import { useStaticQuery, graphql } from "gatsby"

export const useTeam = () => {
    const query = useStaticQuery(graphql`
    {
        list: allSharePointTeamList(
            sort: {fields: data___fields___Order, order: ASC}
        ) {
            edges {
                node {
                    AvatarImage {
                        childImageSharp {
                            gatsbyImageData(
                                width: 200, 
                                layout: FIXED
                            )
                        }
                    }
                    data {
                        fields {
                            Title
                            JobTitle
                            Category
                            About
                            LinkedIn
                            Order
                            Publishedin
                        }
                    }
                }
            }
            categories: distinct(field: data___fields___Category)
        }
        site {
            siteMetadata {
                environment
            }
        }
    }`)
    let {list:{edges:team}} = query
    const {list:{categories}, site:{siteMetadata:{environment}}} = query

    // Filter by environment
    team = team.filter(item => item.node.data.fields.Publishedin.includes(environment))

    // Move Avatar under fields
    team.forEach(member => {
        member.node.data.fields['AvatarImage']=member.node.AvatarImage
    })

    const getByName = (name) => {
        
        let result = team.filter((item) => item.node.data.fields.Title === name)
        result = result[0]?.node?.data?.fields
        
        return result
    }
    const getByCategory = (category) => {
        let result = team.filter((item) => item.node.data.fields.Category === category)
        
        return result.map(r => r.node.data.fields)
    }
    const getTreePerCategory = () => {
        let result = []
        
        categories.forEach(c => {
            result.push({category: c, team: getByCategory(c)})
        })
        return result
    }
    const getAll = () => {
        return team.map(t => t.node.data.fields)
    }

    return {
        getByName,
        getByCategory,
        getTreePerCategory,
        getAll
    }
}