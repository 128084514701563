import React, { useState } from 'react';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

const SingleCount = ({counter,duration,title,color}) => {
   const [viewCountUp,setViewCountUp] = useState(false);

   const onVisibilityChange = (isVisible) => {
      if (isVisible) {
         setViewCountUp(true );
      }
   }
   return (
      <>
         <div className="counter__item  mb-4">
            <h2 className={`counter ${color && color}`}>
               <VisibilitySensor onChange={onVisibilityChange} offset={{ top: 10 }} delayedCall>
                  <CountUp end={viewCountUp ? counter : 0} duration={duration} />
               </VisibilitySensor>
            </h2>
            <span className="fs-4"><b>{title}</b></span>
         </div>
      </>
   );
};

export default SingleCount;