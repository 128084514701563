import React from 'react';
import { FaLinkedinIn } from 'react-icons/fa';

const SingleTeam = ({
   image='',
   name,
   jobTitle,
   about='',
   linkedIn=''
}) => {
   return (
      <>
         <div className="team__item p-relative text-center fix h-100">
            <div className="team__thumb mb-4 d-flex justify-content-center" style={{maxHeight:"200px"}}>
                  <div style={{borderRadius:'99999px', backgroundColor:'aliceblue'}}>{image}</div>
                  <div className="team__info text-start">
                     <h3 className="w-100">{name}</h3>
                     <span>{jobTitle}</span>
                     <div className="mt-3 text-white pe-3" style={{maxHeight: "140px", overflow: "auto", maxWidth: "100%"}}>{about}</div>
                  </div>
                  { linkedIn && 
                     <div className="team__social theme-social">
                        <ul>
                           <li>
                              <a target="_blank" href={linkedIn} rel="noreferrer">
                                 <i > <FaLinkedinIn /> </i>
                                 <i > <FaLinkedinIn /> </i>
                              </a>
                           </li>
                        </ul>
                     </div>
                  }
            </div>
            <div className="team__content">
               <h3>{name}</h3>
               <span>{jobTitle}</span>
            </div>
         </div>
      </>
   );
};

export default SingleTeam;