import React from 'react';

import { useGlobalContext } from '../context/GlobalContext';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header';
import HeroSection from '../components/HeroSection';
import { getImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";
import Seo from "../components/Seo";
import Section from '../components/Section';
import SectionTitle from '../components/Section/SectionTitle';
import { Col, Row } from 'react-bootstrap';
import Button from '../components/Button';
//icons
import IconDb from '../assets/icons/solid/047-database.svg';
import IconTarget from '../assets/icons/solid/050-target.svg';
import IconHand from '../assets/icons/solid/037-hand.svg';
import IconIntelligence from '../assets/icons/solid/041-intelligence.svg';

const Home = ({data, pageContext, location}) => {

  // init page metadata
  const { pages } = useGlobalContext();
  const thisPage = pages.getByPath(location.pathname);

  // retrieve bg image
  const { 
    imageHero, 
   } = data
  
  // get breadcrumbs
  const {breadcrumb: { crumbs }} = pageContext
  
  const bgImage = getImage(imageHero);

  return (
      <>
        <Seo title={thisPage?.Title} description={thisPage?.Description}/>

        <Header />

        {/* 
        HERO SECTION
        */}
        <HeroSection 
            title={thisPage?.Pagetitle}
            bgImage={bgImage}
            breadcrumbs={crumbs}
        />
        
        <Section verticalAlign='top'>
          <SectionTitle>Plan a Maturity Assessment</SectionTitle>
          <Col className="col-lg-6 col-12 mb-5 mb-lg-0">
            <p>Start with a Maturity Assessment based on your current integration or application architecture. You get a second opinion on your existing SAP and non-SAP landscape. We look at your challenges and give you advice on new opportunities and how to reach your ultimate goals faster.</p>
            <h2 className='my-5'>What you'll get</h2>
            <Row className="mb-4">
              <Col className="col-auto">
                <img src={IconDb} alt="Icon for database and connected data containers" width="40px"/>
              </Col>
              <Col>
                Review your existing Non-SAP and/or SAP landscape.
              </Col>
            </Row>
            <Row className="mb-4">
              <Col className="col-auto">
                <img src={IconTarget} alt="Icon for database and connected data containers" width="40px"/>
              </Col>
              <Col>
                Your current solutions, integrations, business processes and running applications.
              </Col>
            </Row>
            <Row className="mb-4">
              <Col className="col-auto">
                <img src={IconHand} alt="Icon for database and connected data containers" width="40px"/>
              </Col>
              <Col>
                Insights into lowering your TCO and how SAP BTP will fit into your new situation.
              </Col>
            </Row>
            <Row className="mb-4">
              <Col className="col-auto">
                <img src={IconIntelligence} alt="Icon for database and connected data containers" width="40px"/>
              </Col>
              <Col>
                How we can inspire and help you with the first steps.
              </Col>
            </Row>
          </Col>
          <Col className="col-lg-5 offset-lg-1 col-12">
          <Button type='primary' href= "https://outlook.office.com/bookwithme/user/04891eb9c9b54b60905f23a63e7ce84a%40aarini.com/meetingtype/8bc4ugoQiU6CPbaqUdFIqg2" target="_blank">Schedule a session now</Button>
          </Col>
        </Section>

        <Footer/>
      </>
  );
};

export default Home;

export const query = graphql`
query Home {
  imageHero: file(relativePath: { eq: "bg_closeup-happy-entrepreneur-having-video-conference-with-his-colleague_1920x1280.jpg" }) {
    childImageSharp {
      gatsbyImageData(
        width: 2000
        quality: 50
        placeholder: BLURRED
        webpOptions: { quality: 70 }
      )
    }
  }
}
`