import React from 'react';
import Section from '../Section';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';

const TextBlock = styled.div`
   //border-left-width: 24px;
   border-left-style: solid;
   border-image: linear-gradient(180deg, var(--vibrant-blue), var(--vibrant-purple)) 1 100%;
   padding: .1rem 2rem;
`

const Inbetweener = ({
   bgColor="black-bg",
   textColor="light",
   smallText=false,
   heightSize='large',
   id='',
   children
}) => {

   return (
      <>
         <Section 
          id={id}
          bgColor={bgColor}
        >
          <Row className={`justify-content-center ${heightSize !== "large" ? "" : "py-md-10"}`}>
            <Col className="col-12 col-lg-7 ps-6">
              <TextBlock className="border-start border-16">
                 <div className="section__title">
                    <h2 className={`${smallText ? 'fs-1' : ''} ${textColor === "light" ? "text-white" : ""}`}>
                      {children}
                    </h2>
                 </div>
              </TextBlock>
            </Col>
          </Row> 
        </Section>
      </>
   );
};

export default Inbetweener;