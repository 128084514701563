import * as React from 'react';

import { useGlobalContext } from '../../context/GlobalContext';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header';
import HeroSection from '../../components/HeroSection';
import { getImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";
import { Col } from "react-bootstrap"
import Seo from "../../components/Seo";
import Inbetweener from '../../components/Inbetweener/Inbetweener';
import ServicesTilesThree from '../../components/ServicesTilesThree';
import SectionJoinCommunity from '../../components/SectionJoinCommunity';

//icons
import IconCloudStorage from '../../assets/icons/solid/016-cloud storage.svg';
import IconArrows from '../../assets/icons/solid/014-arrows.svg';
import IconVisualization from '../../assets/icons/solid/021-data visualization.svg';

const Approach = ({pageContext, data, location}) => {

  // init page metadata
  const { pages } = useGlobalContext();
  const thisPage = pages.getByPath(location.pathname);

  // get breadcrumbs
  const {breadcrumb: { crumbs }} = pageContext
  
  // retrieve bg image
  const {imageCyberSpace} = data

  const bgImage = getImage(imageCyberSpace);
  
  return (
      <>
        <Seo title={thisPage?.Title} description={thisPage?.Description}/>

        <Header />

        {/* 
        HERO SECTION
        */}
        <HeroSection 
            title={thisPage?.Pagetitle}
            bgImage={bgImage}
            buttonScrollElement="approach"
            breadcrumbs={crumbs}
        >
          <Col className="col-12 mt-9">
            <ServicesTilesThree data={
              [
                {
                  icon: IconCloudStorage,
                  title: 'Keep the core clean',
                  to: pages.getByTitle('Keep the core clean')?.Path
                },
                {
                  icon: IconArrows,
                  title: 'Move from A(ABAP) to B(BTP)',
                  to: pages.getByTitle('Move from A(ABAP) to B(BTP)')?.Path
                },
                {
                  icon: IconVisualization,
                  title: 'Gain 360˚ overview on enterprise data',
                  to: pages.getByTitle('Gain 360˚ overview on enterprise data')?.Path
                }
              ]} 
            />
          </Col>
        </HeroSection>
        
        {/*
        INBETWEENER
        */}
        <Inbetweener smallText>
          Enterprises today need to be ready to adapt on fast changing market conditions and need to be supported by real-time data
        </Inbetweener>

        {/*
        SUBSCRIBE TO COMMUNITY 
        */}
        <SectionJoinCommunity bgColor='dark'
          checkItems={[
            "Meet peers of enterprise organizations on every level.",
            "Become part of a closed group on LinkedIn.",
            "Learn from others while exploring innovations.",
            "Share knowledge and experiences in offline and online events.",
          ]}
        />
        <Footer/>
      </>
  );
};

export default Approach;

export const query = graphql`
query Home {
  imageCyberSpace: file(relativePath: { eq: "bg_digital-cyberspace-data-network-connections_1920x1080.jpg" }) {
    childImageSharp {
      gatsbyImageData(
        width: 2000
        quality: 50
        placeholder: BLURRED
        webpOptions: { quality: 70 }
      )
    }
  }
}
`