import React from 'react';
import { Col } from "react-bootstrap";
import { useStaticQuery, graphql } from 'gatsby';
import { getImage } from "gatsby-plugin-image";
import Section from '../Section/Section';
import SectionTitle from '../Section/SectionTitle';
import { useGlobalContext } from '../../context/GlobalContext';
import Button from '../Button';

const SectionCTA = ({
   title='Want to know how we can help?',
   id='',
   buttons=[],
   classes=''
}) => {
   
   const {pages} = useGlobalContext()

   const {imageCyberSpace} = useStaticQuery(graphql`query {
      imageCyberSpace: file(relativePath: { eq: "bg_digital-cyberspace-data-network-connections_1920x1080.jpg" }) {
         childImageSharp {
            gatsbyImageData(
            width: 600
            quality: 50
            placeholder: BLURRED
            webpOptions: { quality: 70 }
            )
         }
      }
   }`)

   const thumb = getImage(imageCyberSpace);

   return (
      <Section 
         id={id}
         thumb={thumb}
         thumbPosition="full"
         title={title}
         classes={classes}
      >
         <Col>
            <SectionTitle align="center" classes="text-white">{title}</SectionTitle>
            <div className="d-flex justify-content-center">
               {buttons.map((b,index) => <Button key={`button-${index}`} to={pages.getByTitle(b?.to)?.Path} OnClick={b?.OnClick}>{b?.text}</Button>)}
            </div>
         </Col>
      </Section>
   );
};

export default SectionCTA;