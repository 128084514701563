import * as React from 'react';

import { useGlobalContext } from '../../context/GlobalContext';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header';
import HeroSection from '../../components/HeroSection';
import { StaticImage, getImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";
import { Col, Row } from 'react-bootstrap';
import Seo from "../../components/Seo";
import Section from '../../components/Section';
import SectionTitle from '../../components/Section/SectionTitle';
import SectionStepByStep from '../../components/SectionStepByStep';
import SectionScheduleAssessment from '../../components/SectionScheduleAssessment/SectionScheduleAssessment';
import SectionFeature from '../../components/SectionFeature/SectionFeature';
import TabsIcon from '../../components/TabsIcon';
import ListCheck from '../../components/ListCheck/ListCheck';
import ListCheckItem from '../../components/ListCheckItem';

// import icons
import depressionIcon from "../../assets/icons/solid/048-depression.svg";
import dbIcon from "../../assets/icons/solid/031-server.svg";
import touchIcon from "../../assets/icons/solid/043-touch.svg";
import cloudIcon from "../../assets/icons/solid/025-cloud server.svg";
import chartIcon from "../../assets/icons/solid/036-pie chart.svg";

const Approach = ({pageContext, data, location}) => {

  // init page metadata
  const { pages } = useGlobalContext();
  const thisPage = pages.getByPath(location.pathname);
  
  // get breadcrumbs
  const {breadcrumb: { crumbs }} = pageContext

  // retrieve bg image
  const { 
    imageCyberSpace
   } = data

  const bgImage = getImage(imageCyberSpace);
  
  const issues = [
    { key:"data-silos", 
      icon: <img alt="Icon" src={dbIcon} width="32px" height="auto" />, 
      label: "Data silos", 
      content: <><p>Having multiple data silos makes sense because it is a natural consequence of having multiple departments within an organization. For example, the marketing department stores its data in one place, product management in another and so on. Within different storage systems, the data is built and managed in different ways. The obvious problem is that this data is not shared, even though different departments could benefit from it.</p></>
    },
    { key:"governance", 
      icon: <img alt="Icon" src={touchIcon} width="32px" height="auto" />, 
      label: "Governance", 
      content: <><p>Data governance is the application of rules and processes that make your data accurate, applicable, compliant and accessible, ensuring the right users have access and can use data they trust.</p>
      <p>To get the most out of the wealth of data, organizations must modernize their approach.</p>
      <p>The basic challenge for most organizations is securely storing and protecting data to meet corporate and regulatory standards, cataloging and categorizing data to make it consistent and meaningful and enabling widespread secure access to the data.</p>
      <p>Today’s data governance leaders understand the organization’s goals and objectives for data governance, build their governance team appropriately and identify the right tools and processes to support their data governance project.</p></>
    },
    { key:"real-time-data", 
      icon: <img alt="Icon" src={cloudIcon} width="32px" height="auto" />, 
      label: "Real time data", 
      content: <><p>To build real-time data pipelines, we need infrastructure and technologies that accommodate ultrafast data capture and processing. To achieve a 360-degree customer view in real-time you need to integrate robust data pipelines.</p>
      <p>Real-time technologies share the following characteristics:</p>
      <ListCheck>
        <ListCheckItem>In-memory data storage for high-speed ingestion.</ListCheckItem>
        <ListCheckItem>Distributed architecture for horizontal scalability.</ListCheckItem>
        <ListCheckItem>They are queryable for real-time, interactive data exploration.</ListCheckItem>
      </ListCheck>
      <p>
        Businesses implement real-time data pipelines in many ways, and each pipeline can look different depending on the type of data,
        workload, and processing architecture. However, all real-time pipelines follow these fundamental principles:
      </p>
      <ListCheck>
        <ListCheckItem>Data must be processed and transformed on the fly so that it is immediately available for querying when it reaches a persistent datastore.</ListCheckItem>
        <ListCheckItem>An operational datastore must be able to run analytics with low latency.</ListCheckItem>
        <ListCheckItem>The system of record must be converged with the system of insight.</ListCheckItem>
      </ListCheck></>
    },
    { key:"data-integration", 
      icon: <img alt="Icon" src={chartIcon} width="32px" height="auto" />, 
      label: "Data integration", 
      content: <>
      <p>Developing and maintaining multiple point-to-point custom integrations is complex. Imagine having data in multiple ERP systems, while running several other applications such as SAP SuccessFactors, SAP Concur, SAP Ariba, or SAP CX Suite. In addition, you may have many third-party applications such as Salesforce or Magento, while in the future you might want to integrate IoT sensors as an extension of your core business processes. In most organizations, integration maturity is low.</p>
      <p>This makes it highly time-consuming to manage all these integrations and security aspects. All the custom integrations make the landscape less scalable. In the end, your maintenance costs will rise exponentially. This means your landscape could be completely fragmented with low governance and integration security.</p></>
    }
  ]

  return (
      <>
        <Seo title={thisPage?.Title} description={thisPage?.Description}/>

        <Header />

        {/* 
        HERO SECTION
        */}
        <HeroSection 
            title={thisPage?.Pagetitle}
            bgImage={bgImage}
            buttonScrollElement="approach"
            breadcrumbs={crumbs}
        />

        <SectionFeature 
          title="Are you scalable enough towards the future?"
          image={<img className="mb-4" src={depressionIcon} alt="" />}
          text="Organizations accumulate an exponentially growing volume of customer data, which often becomes fragmented, duplicated, inconsistent, incomplete, ungoverned, and out-of-date as it travels throughout the organization."
        />
        


        <Section bgColor='dark'>
          <SectionTitle small align='center'>
            Common issues for organizations
          </SectionTitle>
          <Row className="justify-content-center">
            <Col className="col-xl-10">
              <TabsIcon tabs={issues} defaultActiveKey={issues[0].key} />
            </Col>
          </Row>
        </Section>

        <SectionFeature 
          title="SAP Integration Suite as a one-stop holistic solution"
          image={<StaticImage 
              className="mb-4"
              src="../../images/sap_icon_integration_suite.png" 
              alt="Icon of SAP Integration"
              placeholder="none"
              layout="fixed"
              width={120}
              height={120} 
            />}
          text={<><p>SAP integration suite is one of the foundation services that provide bedrock support for all your organizational processes. It offers a one-stop holistic solution to an extremely wide range of integration problems. This brings about the transition from traditional service-oriented architecture to event-driven architecture.</p>
          <p>It is a simplified and scalable solution. You will lower your TCO and become less dependent on integration talent.</p></>}
        />

        {/*
        STEP BY STEP APPROACH
        */}
        <SectionStepByStep 
          showButton={false}
          title="Step by step approach to 360˚ overview on enterprise data"
          checkItems={[
            "Move to SAP HANA/SAP BW for real-time insight into your SAP data.",
            "Setup a data lake with AWS, GCP, AZURE, or HANA Cloud.",
            "Ingest all Non-SAP data or unstructured data in a cost-efficient way into your data lake.",
            "Add all transformations and business logic additions in the various staging layers of the data lake.",
            "Create a lake house to combine a data lake with a business warehouse to enable a 360-degree view of your enterprise data."
          ]}
        />

        {/*
        SCHEDULE ASSESSMENT
        */}
        <SectionScheduleAssessment 
          title="Schedule an Exploration and Advisory Session"
          subtitle="This is what we will cover during our first (online) meeting."
          items={[]}
        />

        <Footer/>
      </>
  );
};

export default Approach;

export const query = graphql`
query Home {
  imageCyberSpace: file(relativePath: { eq: "bg_digital-cyberspace-data-network-connections_1920x1080.jpg" }) {
    childImageSharp {
      gatsbyImageData(
        width: 2000
        quality: 50
        placeholder: BLURRED
        webpOptions: { quality: 70 }
      )
    }
  }
}
`