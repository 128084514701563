import React from 'react';
import { Link } from 'gatsby';
import Button from '../Button';
import { CgArrowLongRight } from 'react-icons/cg';

/**
 * 
 * @param {string} icon Path to the icon for the img src attribute.
 * @param {string} iconHeight Height of the icon in px.
 * @param {string | component} title The title text.
 * @param {string} titleSize @default "lg" Size of the title. Accepts values [sm, lg, xl].
 * @param {string} text Text under the title.
 * @param {string} to Path for the button link.
 * @param {string} bgColor
 */
const SingleService = ({
   icon,
   iconHeight=80,
   title,
   titleSize='lg',
   text,
   to='',
   bgColor='grey-bg-12'
}) => {

   switch (titleSize) {
      case "sm":
         titleSize = 'fs-5'
         break;

      case "xl":
         titleSize = 'fs-1'
         break;
   
      default: //lg
         titleSize = 'fs-3'
         break;
   }
   return (
      <>
         <div className={`services__item services__item-2 mb-4 transition-3 h-100 ${bgColor}`}>
            <div className="services__icon mb-4">
               <img src={icon} alt={`icon for ${title}`} height={`${iconHeight}px`}/>
            </div>
            <div className="services__content">
               <h2 className={titleSize}>
                  {to ? <Link to={to}>{title}</Link> : title }
               </h2>
               <div>{text}</div>
               {to && <Button to={to} variant="link-btn" classes='fs-6'>Read more &nbsp;
                  <i><CgArrowLongRight /> </i>
                  <i><CgArrowLongRight /> </i>
               </Button>}
            </div>
         </div>
      </>
   );
};

export default SingleService;