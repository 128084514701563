import * as React from 'react';
import { useGlobalContext } from '../../../context/GlobalContext';
import Footer from '../../../components/Footer/Footer';
import Header from '../../../components/Header';
import HeroSection from '../../../components/HeroSection';
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";
import { Col } from "react-bootstrap";
import Seo from "../../../components/Seo";
import Section from '../../../components/Section';
import SectionTitle from '../../../components/Section/SectionTitle';
import SectionStepByStep from '../../../components/SectionStepByStep';
import SectionScheduleAssessment from '../../../components/SectionScheduleAssessment/SectionScheduleAssessment';
import SectionJoinCommunity from '../../../components/SectionJoinCommunity';
import Inbetweener from '../../../components/Inbetweener';
import ListCheck from '../../../components/ListCheck/ListCheck';
import ListCheckItem from '../../../components/ListCheckItem';




const Home = ({pageContext, data, location}) => {

  // init page metadata
  const { pages } = useGlobalContext();
  const thisPage = pages.getByPath(location.pathname);

  // retrieve bg image
  const {
    imageCost,
    imageSpecs, 
    imageHero,
    imageSuite
  
   
   } = data

  const bgImage = getImage(imageHero);
  const imgSpecs = getImage(imageSpecs);
  const imgCost = getImage(imageCost);
  const imgSuite = getImage(imageSuite);
  
  // get breadcrumbs
  const {breadcrumb: { crumbs }} = pageContext

  return (
      <>
        <Seo title={thisPage?.Title} description={thisPage?.Description}/>

        <Header />

        {/* 
        HERO SECTION
        */}
        <HeroSection 
            title={thisPage?.Pagetitle}
            titleFullWidth={true}
            bgImage={bgImage}
            bgImagePosition="bottom"
            breadcrumbs={crumbs}
        />

        <Section id="what-is-btp" bgColor='light'>
          <Col className="col-lg-6 col-12">
            <div className="content-above mb-7">
              <SectionTitle>
              Do you have a 360˚ overview on your enterprise data?
              </SectionTitle>
              <div className="features__item-2">
                <p>Are you struggling to respond to fast-changing market conditions? Are you capable of having in-depth insights into your enterprise data? And are you able to provide the business with their data needs quickly? If the answer to any of these questions is no, then it is time to start your future data roadmap journey. This will enable you to capture, process and visualize the ever-growing amount of enterprise data. But it will also provide answers to the increasing business demand for real-time data.</p>
              </div>
            </div>
          </Col>
          <Col className="col-lg-6 col-12 justify-content-center d-flex">
            <GatsbyImage className="rounded-4" image={imgSpecs} alt="man – A man standing – waiting for train"/>
          </Col>
        </Section>

        

        {/* 
        Inbetweener 
        */}
        <Inbetweener bgColor='dark' textColor='dark' >
          Here's what will happen if you don't change...
        </Inbetweener>
        
        
        <Section id="what-is-btp" bgColor='light'>
          <Col className="col-lg-6 col-12">
          <GatsbyImage className="rounded-4" image={imgCost} alt="cost – cost meter – cost meter on the hand"/>
          </Col>
          <Col className="col-lg-6 col-12 justify-content-center d-flex">
          <div className="content-above mb-7">
              <SectionTitle>
              Your landscape will be completely fragmented
              </SectionTitle>
              <ListCheck>
                <ListCheckItem>You are not in control, as you are not steering your organization on fact-based data.</ListCheckItem>
                <ListCheckItem>Due to further data fragmentation, your data landscape’s TCO will keep increasing.</ListCheckItem>
                <ListCheckItem>You cannot support the business with its ever-increasing data demands.</ListCheckItem>
                <ListCheckItem>Governance is out of control.</ListCheckItem>
                <ListCheckItem>Data complaint issues: difficulty generating meaningful business and consumer insights.</ListCheckItem>
                <ListCheckItem>It Is challenging to collect vast amounts of data, add proper governance, security and comply with the regulations affecting it.</ListCheckItem>
                <ListCheckItem>It is challenging to predict costs, avoid vendor lock-in and spin down unused resources.</ListCheckItem>
                <ListCheckItem>A lack of agility to respond to end-customer needs.</ListCheckItem>
                <ListCheckItem>A failure to leverage the connected world.</ListCheckItem>
              </ListCheck>
            </div>
          </Col>
        </Section>


        {/* 
        Inbetweener
         */}
        <Inbetweener bgColor='dark' textColor='dark'>
          Start thinking about a future-proof data integration roadmap
        </Inbetweener>

        {/* 
        SectionStepByStep
         */}
         <SectionStepByStep 
          bgColor='white'
          title="Create your data integration foundation"
          subtitle='A future-proof data integration roadmap is crucial to create the base foundation of your future data landscape.'
          checkItems={[
            "Start by defining your future data architecture roadmap to make the base foundation.",
            "Learn how SAP BTP helps you towards scalable data integration.",
            "Takeaway approach for switching to real-time data integrations.",
            "Learn how to incorporate proper integration security of data at rest or data in motion.",
            "Optimize your current integration patterns (improving the maturity level of your data integration).",
            "Adopt SAP best practices into your data integration landscape.",
          ]}
          imagePosition='right'
          showButton={false}
        />

         {/*
        SCHEDULE ASSESSMENT
        */}
        <SectionScheduleAssessment bgColor='dark'
          title="Start with an Integration exploration and advisory session."
          subtitle="This is what we will cover during our first (online) meeting."
          items={[]}
          buttonText='Plan an exploration session'
        />

         {/*
         Inbetweener 
        */}

       <Inbetweener bgColor='light' textColor='dark'>
          Move to scalable integrations
        </Inbetweener>



        <Section id="what-is-btp" bgColor='dark'>
          <Col className="col-lg-6 col-12">
            <div className="content-above mb-7">
              <SectionTitle>
                Make real-time decisions
              </SectionTitle>
             <ListCheck>
                <ListCheckItem>You are able to make correct, real-time decisions within your complete enterprise consisting of SAP and Non-SAP data sources.</ListCheckItem>
                <ListCheckItem>You will strongly reduce your TCO within your data landscape. </ListCheckItem>
                <ListCheckItem>You will have a properly governed and secure data landscape. Your data experts can switch from operational data tasks to delivering high-value insights within the organization.</ListCheckItem>
                <ListCheckItem>You are able to deliver business data demands faster than ever before. A robust data and analytics strategy together with a clear, actionable roadmap of analytics initiatives and supporting capabilities (people, process and technology) can help your organization stay on track in its pursuit of benefiting from analytics. Business functions within the organization can now work on strategic initiatives that matter the most and drive business value.</ListCheckItem>
             </ListCheck>
            </div>
          </Col>
          <Col className="col-lg-6 col-12 justify-content-center d-flex">
            <GatsbyImage className="rounded-4" image={imgSuite} alt="man – A man standing – waiting for train"/>
          </Col>
        </Section>

        
    
        {/*
         SectionJoinCommunity
          */}
        <SectionJoinCommunity
          title="Subscribe and join 450 like-minded professionals in the “SAP BTP Community”"
          subtitle='Here are the latest questions from the community about simplifying and scaling your integrations to adapt to fast-changing market conditions.'
          checkItems={[
            "Can SAP BTP handle Event-Based Architecture?",
            "Can SAP BTP help me with my security concerns?",
            "How can SAP BTP increase the governance of all developments?",        
          ]}       
        />
        
        <Footer />
      </>
  );
};

export default Home;

export const query = graphql`
query Home {
  imageHero: file(relativePath: { eq: "bg_innovation-finance-concept_1920x1280.jpg" }) {
    childImageSharp {
      gatsbyImageData(
        width: 2000
        height: 720
        quality: 50
        placeholder: BLURRED
        webpOptions: { quality: 80 }
      )
    }
  }
    imageSpecs: file(relativePath: { eq: "young-businesswoman-looking-laptop_1200x800.jpg" }) {
      childImageSharp {
       gatsbyImageData(
         width: 600
         quality: 50
         placeholder: BLURRED
         webpOptions: { quality: 70 }
       )
     }
  }
  imageCost: file(relativePath: { eq: "planning-risk-strategy-business-businessman-gambling-placing-wooden-block-tower_1200x800.jpg" }) {
    childImageSharp {
     gatsbyImageData(
       width: 400
       height: 800
       quality: 50
       placeholder: BLURRED
       webpOptions: { quality: 70 }
     )
   }
 }
 imageSuite: file(relativePath: { eq: "real_time_decisions_900x878.jpg" }) {
    childImageSharp {
     gatsbyImageData(
       width: 600
       quality: 50
       placeholder: BLURRED
       webpOptions: { quality: 70 }
     )
   }
 }
}
`