import { useStaticQuery, graphql } from "gatsby"

export const usePages = () => {
    let {
        site:{siteMetadata:{environment}},
        pages:{edges:content}} = useStaticQuery(graphql`
    {
        pages: allSharePointPagesList {
            edges {
                node {
                    data {
                        fields {
                            Title
                            Slug
                            Pagetitle
                            Subtitle
                            Description
                            Breadcrumb
                            Parent
                            Placeholder
                            Publishedin
                        }
                    }
                }
            }
        }
        site {
            siteMetadata {
                environment
            }
        }
    }`)
    
    // filter by environment
    content = content.filter(item => item.node.data.fields.Publishedin.includes(environment))

    const getByTitle = (title) => {
        
        let result = content.filter((item) => item.node.data.fields.Title === title)
        result = result[0]?.node?.data?.fields
        
        return result
    }

    const getByPath = (path) => {
        
        // remove trailing slash except for homepage
        path = path === '/' ? path : path.replace(/\/$/g,'');
        
        let result = content.filter((item) => item.node.data.fields.Path === path)
        result = result[0]?.node?.data?.fields

        return result
    }

    // Form path for each page
    content.forEach((item) => {
        let currentPage = item.node.data.fields
        let hasParent = currentPage.Parent ? true : false
        let slugs = [currentPage.Slug]
        let path = ''
        
        // Iterate parents
        while (hasParent === true) {
            currentPage = getByTitle(currentPage.Parent)
            slugs.unshift(currentPage.Slug)
            hasParent = currentPage.Parent ? true : false
        }
        path = '/' + slugs.join('/')
        item.node.data.fields['Path'] = path
    })
    
    return {
        getByTitle,
        getByPath
    }
}