import * as React from 'react';
import { useGlobalContext }from '../../../context/GlobalContext';
import Footer from '../../../components/Footer/Footer';
import Header from '../../../components/Header';
import HeroSection from '../../../components/HeroSection';
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";
import { Col } from "react-bootstrap";
import Seo from "../../../components/Seo";
import Section from '../../../components/Section';
import SectionTitle from '../../../components/Section/SectionTitle';
import SectionStepByStep from '../../../components/SectionStepByStep';
import SectionScheduleAssessment from '../../../components/SectionScheduleAssessment/SectionScheduleAssessment';
import SectionJoinCommunity from '../../../components/SectionJoinCommunity';
import Inbetweener from '../../../components/Inbetweener';
import ListCheck from '../../../components/ListCheck/ListCheck';
import ListCheckItem from '../../../components/ListCheckItem';



const Home = ({pageContext, data, location}) => {

  // init page metadata
  const { pages } = useGlobalContext();
  const thisPage = pages.getByPath(location.pathname);

  // retrieve bg image
  const {
    imageCost,
    imageAdapt, 
    imageHero,
    imageScalable,
  
   
   } = data

  const bgImage = getImage(imageHero);
  const imgAdapt = getImage(imageAdapt);
  const imgCost = getImage(imageCost);
  const imgScalable = getImage(imageScalable);
  
  // get breadcrumbs
  const {breadcrumb: { crumbs }} = pageContext

  return (
      <>
        <Seo title={thisPage?.Title} description={thisPage?.Description}/>

        <Header />

        {/* 
        HERO SECTION
        */}
        <HeroSection 
            title={thisPage?.Pagetitle}
            titleFullWidth={true}
            bgImage={bgImage}
            bgImagePosition="bottom"
            breadcrumbs={crumbs}
        />

        <Section id="what-is-btp" bgColor='light'>
          <Col className="col-lg-6 col-12">
            <div className="content-above mb-7">
              <SectionTitle>
              Can you adapt fast enough?
              </SectionTitle>
              <div className="features__item-2">
                <p>If you are still working on the traditional, rigid ABAP development within the core, you will face huge dependencies. Also you will have very limited flexibility to innovate, challenges with keeping the core clean and adapt to fast-changing market conditions. Without innovation, you simply can’t increase your productivity and revenue.</p>
              </div>
            </div>
          </Col>
          <Col className="col-lg-6 col-12 justify-content-center d-flex">
            <GatsbyImage className="rounded-4" image={imgAdapt} alt="man – A man standing – waiting for train"/>
          </Col>
        </Section>

        {/* 
        Inbetweener 
        */}
        <Inbetweener bgColor='dark' textColor='dark'>
          Here's what will happen if you don't change...
        </Inbetweener>
        
        
        <Section id="what-is-btp" bgColor='light'>
          <Col className="col-lg-6 col-12">
          <GatsbyImage className="rounded-4" image={imgCost} alt="cost – cost meter – cost meter on the hand"/>
          </Col>
          <Col className="col-lg-6 col-12 justify-content-center d-flex">
          <div className="content-above mb-7">
              <SectionTitle>
              Your TCO will increase
              </SectionTitle>
              <div className="features__item-2">
                <p>Your TCO will increase even further, while your SAP landscape becomes completely fragmented and low-governed. This causes many application dependencies and makes it impossible to react quickly to fast-changing market conditions. It will also be very difficult to innovate or automate the current business processes. Your revenue will continue to decrease due to the lack of innovation power.</p>
              </div>
            </div>
          </Col>
        </Section>


        {/* 
        Inbetweener
         */}
        <Inbetweener bgColor='dark' textColor='dark'>
          Keep the core clean
        </Inbetweener>

        {/* 
        SectionStepByStep
         */}
        <SectionStepByStep bgColor='white'
          title="Make a change towards success in 3 steps."
          checkItems={[
            "Reduce complexity by building integrations outside the core system",
            "Move your application development to the cloud",
            "Create modular and interchangeable building blocks to increase flexibility",
            
          ]}
          showButton={false}
        />
         
        {/*
        SCHEDULE ASSESSMENT
        */}
        <SectionScheduleAssessment 
          bgColor='dark' 
          topics='services'
          title="Start with an Application Development exploration and advisory session."
          subtitle="This is what we will cover during our first (online) meeting."
          items={[]}
          buttonText='Plan an exploration session'
        />

      {/*
        Inbetweener 
      */}
       <Inbetweener bgColor='white' textColor='dark'>
          Move to a scalable solution
        </Inbetweener>
       

        <Section id="Scalable" bgColor='dark'>
          <Col className="col-lg-6 col-12">
            <div className="content-above mb-7">
              <SectionTitle >
              A simplified and scalable solution
              </SectionTitle>
              <ListCheck>
                <ListCheckItem>You will lower your TCO and become less dependent on specific talent..</ListCheckItem>
                <ListCheckItem>You will also reduce risk. You’ll be able to adapt quickly to fast-changing market conditions and take advantage of faster deployments, increased automation, and greater access to innovation.</ListCheckItem>
                <ListCheckItem>Maintenance and upgrade costs will become things of the past.</ListCheckItem>
              </ListCheck>
            </div>
          </Col>
          <Col className="col-lg-6 col-12 justify-content-center d-flex">
            <GatsbyImage className="rounded-4" image={imgScalable} alt="man – thinking – man on a pc"/>
          </Col>
        </Section>


        {/*
         SectionJoinCommunity
          */}
        <SectionJoinCommunity
          title="Subscribe and join 450 like-minded professionals in the “SAP BTP Community”"
          checkItems={[
            "How can Rapid Application Development lower my TCO?",
            "Is Rapid Application Development the same as agile?",
            "What are the advantages of Rapid Application Development?",
            "How to define the best strategy?",        
          ]}       
        />
        
        <Footer />
      </>
  );
};

export default Home;

export const query = graphql`
query Home {
  imageHero: file(relativePath: { eq: "bg_businesswoman-sitting-office-3d_1920x1280.jpg" }) {
    childImageSharp {
      gatsbyImageData(
        width: 2000
        height: 720
        quality: 50
        placeholder: BLURRED
        webpOptions: { quality: 80 }
      )
    }
  }
  imageAdapt: file(relativePath: { eq: "life-changes-we-adapt-thrive-shot-young-businessman-wearing-face-mask-while-waiting-train-railway-station-during-his-commute_1200x1220.jpg" }) {
      childImageSharp {
       gatsbyImageData(
         width: 600
         quality: 50
         placeholder: BLURRED
         webpOptions: { quality: 70 }
       )
     }
  }
  imageCost: file(relativePath: { eq: "hand_cost_meter.jpg" }) {
    childImageSharp {
     gatsbyImageData(
       width: 600
       height:500
       quality: 50
       placeholder: BLURRED
       webpOptions: { quality: 70 }
     )
   }
 }
 imageScalable: file(relativePath: { eq: "rapid_traffic.jpg" }) {
  childImageSharp {
   gatsbyImageData(
     width: 600
     quality: 50
     placeholder: BLURRED
     webpOptions: { quality: 70 }
   )
 }
}
}
`