import * as React from 'react';

import { useGlobalContext } from '../context/GlobalContext';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header';
import HeroSection from '../components/HeroSection';
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";
import { Col } from "react-bootstrap"
import Seo from "../components/Seo";
import Section from '../components/Section';
import SectionTitle from '../components/Section/SectionTitle';
import SectionFeature from '../components/SectionFeature';
import Inbetweener from '../components/Inbetweener';
import ReferenceLogos from '../components/ReferenceLogos';
import { device } from '../tools/breakpoints';
import styled from 'styled-components';
import ServicesTilesThree from '../components/ServicesTilesThree';

// icons and logos
import logo from '../assets/img/logo/logo-black.svg'
import logoSapPartner from '../images/logo_SAP_partner.svg'
import IconSolving from '../assets/icons/solid/006-problem solving.svg';
import IconQuestion from '../assets/icons/solid/043-question.svg';
import IconFace from '../assets/icons/solid/007-face scanner.svg';

const ColText = styled.div`

  @media ${device.lg} {
    columns: 2;
    column-gap: 4rem;
  }
`

const Home = ({pageContext, data, location}) => {

  // init page metadata
  const { pages } = useGlobalContext();
  const thisPage = pages.getByPath(location.pathname);

  // retrieve bg image
  const { 
    imageHero,
    imageCTA,
    imageHari,
    imageOffice
   } = data

  const bgImage = getImage(imageHero);
  const bgCTA = getImage(imageCTA);
  const imgOffice = getImage(imageOffice);
  const imgHari = getImage(imageHari);
  
  
  // get breadcrumbs
  const {breadcrumb: { crumbs }} = pageContext

  return (
      <>
        <Seo title={thisPage?.Title} description={thisPage?.Description}/>

        <Header />

        {/* 
        HERO SECTION
        */}
        <HeroSection 
            title={thisPage?.Pagetitle}
            titleFullWidth={true}
            bgImage={bgImage}
            bgImagePosition="bottom"
            breadcrumbs={crumbs}
        />
        
        <SectionFeature 
          verticalAlign='top'
          title="Who we are"
          image={<img className="mb-4" width="200px" src={logo} alt="Icon" />}
          text={<><p>Aarini Consulting is a leading ICT company based in the Netherlands, with affiliates in India and United Arab Emirates.
            </p><p>Primarily focused on the SAP and Azure domain, handling three verticals consisting of integrations, application development and data management. Our agnostic approach makes us capable of delivering a wide range of services even beyond SAP and Azure.</p></>}
        />

        <Section id="what-is-btp" bgColor='light'>
          <Col className="col-lg-6 col-12">
            <div className="content-above mb-7">
              <SectionTitle>
                What we do
              </SectionTitle>
              <div className="features__item-2">
                <p>Although primarily focused on SAP, our technology agnostic approach makes us capable of delivering a wide range of services even beyond SAP, mainly in the realm of data management, integrations and extensions, rapid application development, and cloud. We believe that IT should be an enabler, not a bottleneck. We build customer-centric solutions by connecting diverse platforms and thus creating a distributed, service-oriented landscape where data is owned by the business and not the other way round.</p>
              </div>
            </div>
          </Col>
          <Col className="col-lg-6 col-12 justify-content-center d-flex">
            <GatsbyImage className="rounded-4" image={imgHari} alt="Hari – the Aarini CTO – giving speach in Aarini office"/>
          </Col>
        </Section>
        
        <SectionFeature 
          verticalAlign='top'
          title="We are a trusted certified SAP Partner"
          image={<img className="mb-4" width="300px" src={logoSapPartner} alt="Icon" />}
          text={<><p>We have received certification within the SAP PartnerEdge Program. Typically, these organizations are in the business of aiding companies in implementing, servicing, supporting, and extending SAP functionality.</p></>}
        />

        <Section id="what-is-btp" bgColor='light'>
          <Col className="col-lg-6 col-12">
            <div className="content-above mb-7">
              <SectionTitle>
                Where we add value
              </SectionTitle>
              <div className="features__item-2">
                <p>Change and innovation doesn't have to be scary, nor expensive. We help customers utilise full value of their existing technology thus adding a competitive edge and transforming customer's experience in an unimaginable way.</p>
                <p>We are committed to guiding our customers on their transformation journey and lead them to success.</p>
              </div>
            </div>
          </Col>
          <Col className="col-lg-6 col-12 justify-content-center d-flex">
            <GatsbyImage className="rounded-4 mb-4" image={imgOffice} alt="Office building of Aarini"/>
          </Col>
        </Section>
        
        <ReferenceLogos title ='We guide these enterprise organizations towards a clean core' bgColor='dark' />

        {/*
        INBETWEENER
        */}
        <Inbetweener smallText>
          Our uniqueness is that we provide in-depth SAP knowledge with a wide variety of the latest open-source technologies.
        </Inbetweener>

        <Section>
          <SectionTitle align='center'>
            How we work
          </SectionTitle>
          <ColText>
            <p>At Aarini, we developed our <b>own delivery methodology</b> based on actual projects from over 15 years long experience. We have refined popular methodologies and established a process flow that has proven to bring the most value.</p>
            <p>Using <b>design thinking</b> methods and in close collaboration with our clients, we discover the true problem, rapidly validate solutions, and propose those that give the most <b>competitive edge</b>. Results thereof form a base to an initial backlog which is later on – again together with the client – refined and that serves as an action plan. Client plays active part in the product development as well.</p>
            <p>As product owner, the <b>client decides on value delivery</b>, keeps the <b>costs under control</b>, and has a say in project planning.</p>
            <p>Using this approach allows for launching a minimum viable product fast, in a sustainable and controllable way. Leveraging the power of DevOps, user feedback, and agile development stemming from the learn &gt; iterate &gt; decide &gt; deliver &gt; repeat cycle, we then expand the product towards a <b>mature and exceptional solution</b>.</p>
            <p>Consequently, based on real data, <b>user feedback</b> and new constraints, we keep improving for even <b>better experience</b>.</p>
          </ColText>
        </Section>

        <Section 
          id="section-cta"
          thumb={bgCTA}
          thumbPosition='full'
          horizontalAlign='center'
        >
          <ServicesTilesThree data={
            [
              {
                icon: IconSolving,
                title: 'Our approach',
                to: pages.getByTitle('Approach')?.Path
              },
              {
                icon: IconQuestion,
                title: 'Ask a question',
                to: pages.getByTitle('Contact')?.Path
              },
              {
                icon: IconFace,
                title: 'Meet our team',
                to: pages.getByTitle('Team')?.Path
              }
            ]}
          />
        </Section>

        <Footer/>
      </>
  );
};

export default Home;

export const query = graphql`
query Home {
  imageHero: file(relativePath: { eq: "bg_aarini_office_worker_working_1920x1280.jpg" }) {
    childImageSharp {
      gatsbyImageData(
        width: 2000
        quality: 50
        placeholder: BLURRED
        webpOptions: { quality: 70 }
      )
    }
  }
  imageCTA: file(relativePath: { eq: "bg_digital-cyberspace-data-network-connections_1920x1080.jpg" }) {
    childImageSharp {
      gatsbyImageData(
        width: 2000
        quality: 50
        placeholder: BLURRED
        webpOptions: { quality: 70 }
      )
    }
  }
  imageHari: file(relativePath: { eq: "aarini_ceo_harikishore_giving_speech.jpeg" }) {
    childImageSharp {
      gatsbyImageData(
        width: 300
        quality: 50
        placeholder: BLURRED
        webpOptions: { quality: 70 }
      )
    }
  }
  imageOffice: file(relativePath: { eq: "aarini_office_building_1920x1280.jpg" }) {
    childImageSharp {
      gatsbyImageData(
        width: 500
        quality: 50
        placeholder: BLURRED
        webpOptions: { quality: 70 }
      )
    }
  }
}
`