import React from "react";

const ListCheck = ({classes='',children}) => {
    return (
        <>
            <div className={`about__list ${classes}`}>
                <ul>
                    { children }
                </ul>
            </div>
        </>
    )
}

export default ListCheck;