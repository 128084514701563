import * as React from 'react';
import { useGlobalContext }from '../../../context/GlobalContext';
import Footer from '../../../components/Footer/Footer';
import Header from '../../../components/Header';
import HeroSection from '../../../components/HeroSection';
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";
import { Col } from "react-bootstrap"
import Seo from "../../../components/Seo";
import Section from '../../../components/Section';
import SectionTitle from '../../../components/Section/SectionTitle';
import SectionStepByStep from '../../../components/SectionStepByStep';
import SectionScheduleAssessment from '../../../components/SectionScheduleAssessment/SectionScheduleAssessment';
import SectionJoinCommunity from '../../../components/SectionJoinCommunity';
import Inbetweener from '../../../components/Inbetweener';
import ListCheckItem from '../../../components/ListCheckItem';
import ListCheck from '../../../components/ListCheck/ListCheck';

const Home = ({pageContext, data, location}) => {

  // init page metadata
  const { pages } = useGlobalContext();
  const thisPage = pages.getByPath(location.pathname);

  // retrieve bg image
  const {
    imageCost,
    imageAdapt, 
    imageHero,
    imageSolution
   } = data

  const bgImage = getImage(imageHero);
  const imgAdapt = getImage(imageAdapt);
  const imgCost = getImage(imageCost);
  const imgSolution = getImage(imageSolution);
  
  // get breadcrumbs
  const {breadcrumb: { crumbs }} = pageContext

  return (
      <>
        <Seo title={thisPage?.Title} description={thisPage?.Description}/>

        <Header />

        {/* 
        HERO SECTION
        */}
        <HeroSection 
            title={thisPage?.Pagetitle}
            titleFullWidth={true}
            bgImage={bgImage}
            bgImagePosition="bottom"
            breadcrumbs={crumbs}
        />
        
        <Section id="what-is-btp" bgColor='light'>
          <Col className="col-lg-6 col-12">
            <div className="content-above mb-7">
              <SectionTitle >
              Is your application scalable enough?
              </SectionTitle>
              <div className="features__item-2">
                <p>Rigid ABAP development within the core is causing huge dependencies and very limited flexibility to innovate and adapt to fast-changing market conditions.</p>
              </div>
            </div>
          </Col>
          <Col className="col-lg-6 col-12 justify-content-center d-flex">
            <GatsbyImage className="rounded-4" image={imgAdapt} alt="girl – working– working on a computer"/>
          </Col>
        </Section>

        

        {/* 
        Inbetweener 
        */}
        <Inbetweener bgColor='dark' textColor='dark'>
          Here's what will happen if you don't change...
        </Inbetweener>
        
        <Section id="what-is-btp" bgColor='light'>
          <Col className="col-lg-6 col-12">
          <GatsbyImage className="rounded-4" image={imgCost} alt="cost – cost meter – cost meter on the hand"/>
          </Col>
          <Col className="col-lg-6 col-12 justify-content-center d-flex">
          <div className="content-above mb-7">
              <SectionTitle>
              Your TCO will increase
              </SectionTitle>
              <div className="features__item-2">
                <p>Your TCO will increase even further, while your SAP landscape becomes completely fragmented and low-governed. This causes many application dependencies and makes it impossible to react quickly to fast-changing market conditions. It will also be very difficult to innovate or automate the current business processes.</p>
                <p>Your revenue will continue to decrease due to the lack of innovation power.</p>
              </div>
            </div>
          </Col>
        </Section>


        {/* 
        Inbetweener
         */}
        <Inbetweener bgColor='dark' textColor='dark'>
          Keep the core clean
        </Inbetweener>

        {/* 
        SectionStepByStep
         */}
        <SectionStepByStep bgColor='white'
          title="Make a change towards success in 7 steps."
          checkItems={[
            "Start with a Composable business.",
            "Find out how Event Mesh and API Management help you move towards your future-proof integration landscape.",
            "Find out how to incorporate event-driven and API-led integration into your organization.",
            "Takeaway approach for switching to real-time integrations between SAP and non-SAP (A2A).",
            "Learn about the 5 steps to incorporating proper integration security of data at rest or data in motion.",
            "Optimize your current integration patterns (improving the maturity level of integration).",
            "Adopt SAP best practices into your integration landscape.",
          ]}
          showButton={false}
        />
         
        {/*
        SCHEDULE ASSESSMENT
        */}
        <SectionScheduleAssessment bgColor='dark' topics='services'
          title="Start with an Application Development exploration and advisory session."
          subtitle="This is what we will cover during our first (online) meeting."
          items={[]}
          buttonText='Plan an exploration session'
        />

        {/*
         Inbetweener 
        */}

       <Inbetweener bgColor='white' textColor='dark'>
          Move to a scalable solution
        </Inbetweener>
       

        <Section id="Scalable" bgColor='dark'>
          <Col className="col-lg-6 col-12">
            <div className="content-above mb-7">
              <SectionTitle >
              A simplified and scalable way of integrating your applications.
              </SectionTitle>
              <ListCheck>
                <ListCheckItem>You will lower your TCO and become less dependent on integration talent.</ListCheckItem>
                <ListCheckItem>You will also reduce risk. You’ll be able to adapt quickly to fast-changing market conditions and take advantage of faster deployments, increased automation and greater access to innovation.</ListCheckItem>
                <ListCheckItem>Maintenance and upgrade costs will become things of the past.</ListCheckItem>
              </ListCheck>
            </div>
          </Col>
          <Col className="col-lg-6 col-12 justify-content-center d-flex">
            <GatsbyImage className="rounded-4" image={imgSolution} alt="man – thinking – man on a pc"/>
          </Col>
        </Section>

        {/*
         SectionJoinCommunity
          */}
        <SectionJoinCommunity
          title="Subscribe and join 450 like-minded professionals in the “SAP BTP Community”"
          checkItems={[
            "How can I lower the dependency between applications",
            "Is Rapid Application Development the same as agile?",
            "How can I lower my vulnerability within the security of my applications integrations?",
            "How to define the best strategy?",        
          ]}
          
        />
        <Footer />
      </>
  );
};

export default Home;

export const query = graphql`
query Home {
  imageHero: file(relativePath: { eq: "bg_businesswoman-sitting-office-3d_1920x1280.jpg" }) {
    childImageSharp {
      gatsbyImageData(
        width: 2000
        quality: 50
        placeholder: BLURRED
        webpOptions: { quality: 80 }
      )
    }
  }
    imageAdapt: file(relativePath: { eq: "anxious-confused-businesswoman-having-problem-with-pc-laptop-headshot-portrait_1200x800.jpg" }) {
      childImageSharp {
       gatsbyImageData(
         width: 600
         quality: 50
         placeholder: BLURRED
         webpOptions: { quality: 70 }
       )
     }
  }
  imageCost: file(relativePath: { eq: "hand_cost_meter.jpg" }) {
    childImageSharp {
     gatsbyImageData(
       width: 600
       height: 500
       quality: 50
       placeholder: BLURRED
       webpOptions: { quality: 70 }
     )
    }
  }
  imageSolution: file(relativePath: { eq: "smiling-attractive-businesswoman-drinking-invigorating-coffee-during-break-workplace_1200x800.jpg" }) {
    childImageSharp {
    gatsbyImageData(
      width: 600
      quality: 50
      placeholder: BLURRED
      webpOptions: { quality: 70 }
    )
    }
  }
}`