import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Section from '../Section/Section';
import SectionTitle from '../Section/SectionTitle';
import SectionContentRight from '../Section/SectionContentRight';
import Button from '../Button';

import { getImage } from "gatsby-plugin-image";
import { useGlobalContext } from '../../context/GlobalContext';
import ListCheck from '../ListCheck/ListCheck';
import ListCheckItem from '../ListCheckItem';

function SectionJoinCommunity({
  bgColor = 'light', 
  checkItems = [], 
  title = 'Subscribe and join 450 like-minded professionals', 
  subtitle = 'Here are the latest questions from the community related to Rapid application development scalability:', 
  buttonText = 'Join the community'
}) {

  const { pages } = useGlobalContext();

  const { imageCommunity } = useStaticQuery(graphql`query {
      imageCommunity: file(relativePath: { eq: "creative-business-people-listening-colleague_1200x800.jpg" }) {
        childImageSharp {
            gatsbyImageData(
            width: 600
            quality: 50
            placeholder: BLURRED
            webpOptions: { quality: 70 }
            )
        }
      }
    }
  `);
  const thumbCommunity = getImage(imageCommunity);

  return (
    <Section
      id="subscribe-to-community"
      bgColor={bgColor}
      thumb={thumbCommunity}
    >
      <SectionContentRight>
        <SectionTitle small={true}>
          {title}
        </SectionTitle>
        <div className="fs-5 mb-6">{subtitle}</div>
        <ListCheck classes="mb-6">
          {checkItems.map((item, index) => <ListCheckItem key={`checklistitem-${index}`}>
            {item}
          </ListCheckItem>
          )}
        </ListCheck>
        <Button to={pages.getByTitle('Community').Path}>{buttonText}</Button>
      </SectionContentRight>
    </Section>
  );
}

export default SectionJoinCommunity;