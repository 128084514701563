import React from 'react';

const SectionContentRight = ({children}) => {
   
   return (
      <>
         <div className="col-xl-5 offset-xl-7 col-lg-6 offset-lg-6">
            <div className="faq__wrapper">
               {children}
            </div>
         </div>
      </>
   );
};

export default SectionContentRight;