import React, { useState, createContext, useContext, useEffect } from 'react';
import { usePages } from '../hooks/usePages';
import { useMenus } from '../hooks/useMenus';
import { useVariables } from '../hooks/useVariables';

const GlobalContext = createContext();

/* 
* Context provider
*/
export const GlobalContextProvider = ({ children }) => {
   
   /* 
   * Context values
   */
   const pages = usePages()

   const menus = useMenus()

   const variables = useVariables()

   const [cookieConsentBarOpen, setCookieConsentBarOpen] = useState(true);

   const [isVideoOpen, setIsVideoOpen] = useState(false)

   const [isMenuVisible, setIsMenuVisible] = useState(true)
      
   // sticky menu
   const [stickyMenu, setStickyMenu] = useState(false);
   useEffect(() => {
      const stickyMenuBar = () => {
         if (window.scrollY > 80) {
            setStickyMenu(true)
         }
         else {
            setStickyMenu(false)
         }
      }
      window.addEventListener('scroll', stickyMenuBar);
   }, [])

   const value = {
      pages, 
      menus,
      variables,
      cookieConsentBarOpen,
      setCookieConsentBarOpen,
      isVideoOpen,
      setIsVideoOpen,
      stickyMenu,
      setStickyMenu,
      isMenuVisible,
      setIsMenuVisible
   }

   return (
      <GlobalContext.Provider value={value}>
         {children}
      </GlobalContext.Provider>
   );
};

export const useGlobalContext = () => {
   const context = useContext(GlobalContext)
   if (context === undefined) {
     throw new Error('useGlobalContext must be used within a GlobalContextProvider')
   }
   return context
}