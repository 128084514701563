import React from 'react';

const SectionSubtitle = ({
   align="left",
   classes='',
   children
}) => {
   
   return (
      <div className={`fs-5 mb-6 ${classes} text-${align}`} style={{lineHeight:1.5}}>
         {children}
      </div>
   );
};

export default SectionSubtitle;