import * as React from "react";
import { useGlobalContext } from "../context/GlobalContext";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header";
import HeroSection from "../components/HeroSection";
import { getImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";
import Seo from "../components/Seo";
import Section from "../components/Section";
import SectionTitle from "../components/Section/SectionTitle";
import ListCheck from "../components/ListCheck/ListCheck";
import ListCheckItem from "../components/ListCheckItem";
import SectionSubtitle from "../components/Section/SectionSubtitle";
import SectionFeature from "../components/SectionFeature";

// icons and logos
import iconChat from "../assets/icons/solid/019-conversation.svg";

const Home = ({ pageContext, data, location }) => {
  // init page metadata
  const { pages } = useGlobalContext();
  const thisPage = pages.getByPath(location.pathname);

  // retrieve bg image
  const { imageHero } = data;

  const bgImage = getImage(imageHero);

  // get breadcrumbs
  const {
    breadcrumb: { crumbs },
  } = pageContext;

  return (
    <>
      <Seo title={thisPage?.Title} description={thisPage?.Description} />

      <Header />

      {/* 
        HERO SECTION
        */}
      <HeroSection
        title={thisPage?.Pagetitle}
        titleFullWidth={true}
        bgImage={bgImage}
        bgImagePosition="center"
        breadcrumbs={crumbs}
      />

      <Section verticalAlign="top">
        <div className="text-center">
          <SectionTitle>SAP Functional Consultant</SectionTitle>
          <SectionSubtitle>
            Aarini works at the forefront of IT technology. It’s chosen
            specialization is SAP and Microsoft. Aarini is SAP Business
            Technology Platform partner of SAP. It has offices in The
            Netherlands, India and Dubai. More information can be found on this
            website. It has grown considerably the last years and wants to grow
            in the future. Because of this growth, Aarini is looking for the
            best talent. We hope to grow together in the future.
          </SectionSubtitle>
        </div>
      </Section>

      <SectionFeature
        verticalAlign="start"
        bgColor="dark"
        title="Integrating SAP BTP solutions"
        image={<img className="mb-4" src={iconChat} alt="Icon" />}
        text={
          <>
            <p>
              SAP Business Technology Platform (SAP BTP) is a PaaS offering from
              SAP supporting customers and its partners to build intelligent
              enterprise applications. SAP BTP offers both technology
              capabilities and business services that can be used to create
              modern applications for enterprises.
            </p>
          </>
        }
      />

      <Section verticalAlign="top">
        <div className="text-center">
          <SectionSubtitle>
            We have already a SAP functional team which helps on internal and
            external projects to integrate the solutions we build in SAP with
            SAP BTP or other middleware solutions. Also to work together with
            our customers to build/advise SAP standard solutions in combination
            with SAP BTP solutions which can extend their business. You will be
            the SAP functional member in the areas of MM, SD, WM, EWM, TM, PP,
            QM, PM, CS or any other SAP functional module.
          </SectionSubtitle>
        </div>
      </Section>

      <Section verticalAlign="top" bgColor="dark">
        <div className="text-center">
          <SectionTitle>
            The position of (junior, medior senior) SAP functional consultant
          </SectionTitle>
          <SectionSubtitle>
            As integration with SAP solutions becomes more and more important in
            the SAP architecture at multinationals, large enterprises, small and
            medium sized companies, Aarini wants to hire Dutch speaking SAP
            functional consultants to extend its 35 Indian/international team
            here in The Netherlands. We see the following steps to start your
            SAP career at Aarini:
          </SectionSubtitle>
        </div>
        <ListCheck classes="col-lg-10">
          <ListCheckItem>Onboarding at Aarini</ListCheckItem>
          <ListCheckItem>
            Access to learning.sap.com and other resources to learn
          </ListCheckItem>
          <ListCheckItem>
            Support and knowledge transfer from Aarini’s squad leads,
            developers, CTO.
          </ListCheckItem>
          <ListCheckItem>
            Assignment to a project and start extending your knowledge of SAP
            BTP and SAP S/4 HANA Cloud/Public/Private
          </ListCheckItem>
          <ListCheckItem>
            Become certified in SAP S/4 HANA Private/Public Cloud.
          </ListCheckItem>
          <ListCheckItem>
            Grow in knowledge, experience, become a squad lead or develop your
            own business within Aarini
          </ListCheckItem>
        </ListCheck>
      </Section>

      <Section verticalAlign="top">
        <div className="text-center">
          <SectionTitle>Requirements</SectionTitle>
        </div>
        <ListCheck classes="col-lg-10">
          <ListCheckItem>HBO or University-degree </ListCheckItem>
          <ListCheckItem>Will to win</ListCheckItem>
          <ListCheckItem>Dutch-speaking </ListCheckItem>
        </ListCheck>
      </Section>

      <Section verticalAlign="top">
        <div className="text-center">
          <SectionTitle>Aarini offers</SectionTitle>
        </div>
        <ListCheck classes="col-lg-10">
          <ListCheckItem>
            Base salary starting from 3.250 € / month
          </ListCheckItem>
          <ListCheckItem>
            8,33% of base salary as Holiday allowance per month{" "}
          </ListCheckItem>
          <ListCheckItem>
            10% of the Base salary paid every year based on the employee and
            company performance for a full calendar year as bonus.{" "}
          </ListCheckItem>
          <ListCheckItem>
            Steep salary increases in the years to come based on performance.{" "}
          </ListCheckItem>
          <ListCheckItem>
            Reimbursement of travel expenses or Lease car with a starting budget
            of 500 Euros excl BTW and fuel card.{" "}
          </ListCheckItem>
          <ListCheckItem>
            Support and knowledge transfer from Aarini’s squad leads,
            developers, CTO.{" "}
          </ListCheckItem>
          <ListCheckItem>
            Possibility to grow in knowledge and experience{" "}
          </ListCheckItem>
          <ListCheckItem>
            Being part of an entrepreneurial environment.
          </ListCheckItem>
        </ListCheck>
      </Section>

      <SectionFeature
        id="join-our-team"
        title="Join our team"
        bgColor="dark"
        text={
          <>
            We welcome open solicitations. If you are interested in joining our
            team at Aarini please send your CV and cover letter to Murat Keles{" "}
            <a
              href="mailto:murat.keles@aarini.com?subject=Join%20our%20team%20at%20aarini%20-%20SAP%20Functional%20Consultant"
              rel="noreferrer"
              target="_blank"
            >
              murat.keles@aarini.com{" "}
            </a>
          </>
        }
      />

      <Footer />
    </>
  );
};

export default Home;

export const { query, imageCommunity } = graphql`
  query Home {
    imageHero: file(
      relativePath: {
        eq: "bg_event_sap experience center_march 2023_IMG_6789 2.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 2000
          quality: 50
          placeholder: BLURRED
          webpOptions: { quality: 70 }
        )
      }
    }
    imageCommunity: file(
      relativePath: { eq: "aarini_ceo_harikishore_giving_speech_720x811.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 600
          quality: 50
          placeholder: BLURRED
          webpOptions: { quality: 70 }
        )
      }
    }
  }
`;
