import { useStaticQuery, graphql } from "gatsby"
import { usePages } from "./usePages"

export const useMenus = () => {
    let {menus:{edges:content}, site:{siteMetadata:{environment}}} = useStaticQuery(graphql`
    {
        menus: allSharePointMenusList(
            sort: {fields: data___fields___Order}
        ){
            edges {
                node {
                    data {
                        fields {
                            Location
                            Title
                            Page
                            Slug: Page_x003a__x0020_Slug
                            Order
                            Parent
                            Environment
                            Externallink
                        }
                    }
                }
            }
        }
        site {
            siteMetadata {
                environment
            }
        }
    }`)

    const pages = usePages()

    // Treat empty slugs
    content.forEach(item => {
        item.node.data.fields.Slug = item.node.data.fields.Slug || ''
        item.node.data.fields['Path'] = pages.getByTitle(item.node.data.fields.Page)?.Path || ''
    })

    // Filter by environment
    content = content.filter(item => item.node.data.fields.Environment.includes(environment))

    const get = (location) => {
        let result = content.filter((item) => item.node.data.fields.Location === location)
        return result || []
    }
    const getTree = (
        location,
        {idKey='Title', parentKey='Parent', childrenKey='children'} = {}
    ) => {
        const data = content.filter((item) => item.node.data.fields.Location === location)
        const tree = [];
        const childrenOf = {};
        data.forEach((item) => {
            const newItem = {...item.node.data.fields};
            const { [idKey]: id, [parentKey]: parentId = 0 } = newItem;
            childrenOf[id] = childrenOf[id] || [];
            newItem[childrenKey] = childrenOf[id];
            parentId
                ? (
                    childrenOf[parentId] = childrenOf[parentId] || []
                ).push(newItem)
                : tree.push(newItem);
        });
        
        return tree;
    };
    return {get, getTree}
}