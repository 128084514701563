import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "../Button";
import { Col } from "react-bootstrap";
import { useGlobalContext } from "../../context/GlobalContext";

const ModalJoinCommunity = ({ 
    buttonText="Join the community",
    buttonClasses=''
}) => {

    const { variables } = useGlobalContext();
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [jobTitle, setJobTitle] = useState("");
    const [phone, setPhone] = useState("");
    const [company, setCompany] = useState("");
    const [country, setCountry] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [response, setResponse] = useState("");
    const handleShow = () => setShowModal(true);
    const handleClose = () => {
        setShowModal(false)
        setTimeout(()=>setResponse(""), 500);
    };
    
    const handleSubmit = async (e) => {    
        e.preventDefault();
        try {
            let res = await fetch("/api/contact", {
                method: "POST",
                body: JSON.stringify({
                    formLocation: "Community",
                    name,
                    email,
                    phone,
                    jobTitle,
                    company,
                    country
                }),
            });
            if (res.status === 200) {
                setName("");
                setEmail("");
                setPhone("");
                setJobTitle("");
                setCompany("");
                setCountry("");
                setShowModal(true);
                setResponse("Thank you for reaching out. We will get back to you within 24 hours.");
            } else {
                setShowModal(true);
                setResponse(<>Sorry, some error occurred. Please try it again later, or send us an email to <a href={`mailto:${variables.get('Email info')}`} target="_blank" rel="noreferrer">{variables.get('Email info')}</a></>);
            }
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <>
            <Modal aria-labelledby="contained-modal-title-vcenter" centered show={showModal} onHide={handleClose}>
                <Modal.Header className="border-0" closeButton>
                    <Modal.Title>Join the SAP BTP Community</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {response ? <div>{response}</div> :
                        <Col className="contact__form w-100 col-12">
                            <form onSubmit={handleSubmit}>
                                <input type="text" value={name} onChange={(e) => setName(e.target.value)} required placeholder="Your Name *" />
                                <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required placeholder="Email address *" />
                                <input type="text" value={phone} onChange={(e) => setPhone(e.target.value)} placeholder="Phone number" />
                                <input type="text" value={jobTitle} onChange={(e) => setJobTitle(e.target.value)} placeholder="Job title" />
                                <input type="text" value={company} onChange={(e) => setCompany(e.target.value)} required placeholder="Company *" />
                                <input type="text" value={country} onChange={(e) => setCountry(e.target.value)} placeholder="Country" />
                                <Button variant="primary" type="submit">Submit</Button>
                            </form>
                        </Col>
                    }
                </Modal.Body>
            </Modal>
            <Button to="" OnClick={handleShow} classes={buttonClasses}>{buttonText}</Button>
        </>
    );
};

export default ModalJoinCommunity;
