import React, { useState } from 'react';

import HeaderBg from '../components/HeaderBg/HeaderBg';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header';
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";
import Seo from "../components/Seo";
import Section from '../components/Section';
import SectionTitle from '../components/Section/SectionTitle';
import { Col, Row, Modal } from 'react-bootstrap';
import { sanitize } from '../tools/content';
import ContactFormDownloads from '../components/ContactFormDownloads/ContactFormDownloads';

const Downloads = ({data}) => {

  // retrieve bg image
  const { 
    imageHero, 
    item:{data:{fields:download}},
    item:{ThumbnailImage},
    item:{PreviewImage}
   } = data

  const bgImage = getImage(imageHero);
  
  const [open, setOpen] = useState(false);

  return (
      <>
        <Seo title={download.Pagetitle} description={download.Description}/>

        <Header showMenu={false} />

        {/* 
        Header background
        */}
        <HeaderBg 
            title={download.Pagetitle}
            bgImage={bgImage}
        />
        
        <Section verticalAlign='top'>
          <Col className="col-xl-6 col-12 mb-5 mb-xl-0">
            <SectionTitle>{download.Pagetitle}</SectionTitle>
            <div className='my-5'>{sanitize(download.Description)}</div>
            <Row className="mb-5 align-items-end">
              <Col className="col-xl-6 col-lg-4 col-5">
                <button onClick={()=>setOpen(true)} className='w-100'>
                  <GatsbyImage 
                    className="w-100" image={getImage(ThumbnailImage)} alt="Thumbnail image of the download" 
                    style={{border:"thin solid silver", cursor: "zoom-in", WebkitCursor: "zoom-in", msCursor: "zoom-in"}}
                    imgStyle={{maxWidth:"100%", maxHeight:"100%", height:"auto", width:"100%"}}
                  />
                </button>
              </Col>
              <Col className="col-xl-6 col-12">
                <div className="ms-1" style={{fontSize:"1rem"}}>{sanitize(download.Details)}</div>
              </Col>
            </Row>
            <div className="download-footer" style={{fontSize:"0.875rem"}}>{sanitize(download.Footer)}</div>
          </Col>
          <Col className="col-xl-5 offset-xl-1 col-12 mt-0 mt-xxl-6">
            <div className="sticky-xl-top pt-0 pt-xl-5" style={{zIndex:0}}>
              <ContactFormDownloads link={download.Link}/>
            </div>
          </Col>
        </Section>

        <Footer/>

        <Modal
         fullscreen={true}
         show={open}
         onHide={() => setOpen(false)}
         dialogClassName="modal-dialog-centered"
         >
            <Modal.Header closeButton closeVariant='white' closeLabel='close'  className=' border-0'/>
            <Modal.Body >
              <GatsbyImage className="" image={getImage(PreviewImage)} alt="Preview of the download" 
                style={{border:"thin solid silver", margin:"auto"}}
                imgStyle={{maxWidth:"100%"}}
              />
            </Modal.Body>
            <style>
            {`.modal-content {
               background-color: rgba(0, 0, 0, 0.7);
               border: none;
            }`}
            </style>
         </Modal>

      </>
  );
};

export default Downloads;

export const query = graphql`
query($Title: String!) {
  item: sharePointDownloadsList(
    data: {fields: {Title: {eq: $Title}}}
  ) {
    ThumbnailImage {
      childImageSharp {
        gatsbyImageData(
          width: 300
          layout: CONSTRAINED
        )
      }
    }
    PreviewImage {
      childImageSharp {
        gatsbyImageData(
          layout: FIXED
        )
      }
    }
    data {
      fields {
        Title
        Pagetitle
        Description
        Link
        Details
        Footer
        Publishedin
      }
    }
  }
  imageHero: file(relativePath: { eq: "bg_digital-cyberspace-data-network-connections_1920x1080.jpg" }) {
    childImageSharp {
      gatsbyImageData(
        width: 2000
        quality: 50
        placeholder: BLURRED
        webpOptions: { quality: 70 }
      )
    }
  }
}`