import * as React from 'react';
import { useGlobalContext } from '../../../context/GlobalContext';
import Footer from '../../../components/Footer/Footer';
import Header from '../../../components/Header';
import HeroSection from '../../../components/HeroSection';
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";
import { Col } from "react-bootstrap";
import Seo from "../../../components/Seo";
import Section from '../../../components/Section';
import SectionTitle from '../../../components/Section/SectionTitle';
import SectionStepByStep from '../../../components/SectionStepByStep';
import SectionScheduleAssessment from '../../../components/SectionScheduleAssessment/SectionScheduleAssessment';
import Inbetweener from '../../../components/Inbetweener';



const Home = ({pageContext, data, location}) => {

  // init page metadata
  const { pages } = useGlobalContext();
  const thisPage = pages.getByPath(location.pathname);

  // retrieve bg image
  const {
    imageCost,
    imageAdapt, 
    imageHero,
    imageSuite
  
   
   } = data

  const bgImage = getImage(imageHero);
  const imgAdapt = getImage(imageAdapt);
  const imgCost = getImage(imageCost);
  const imgSuite = getImage(imageSuite);
  
  // get breadcrumbs
  const {breadcrumb: { crumbs }} = pageContext

  return (
      <>
        <Seo title={thisPage?.Title} description={thisPage?.Description}/>

        <Header />

        {/* 
        HERO SECTION
        */}
        <HeroSection 
            title={thisPage?.Pagetitle}
            titleFullWidth={true}
            bgImage={bgImage}
            bgImagePosition="bottom"
            breadcrumbs={crumbs}
        />

        <Section id="maintenanace_cost" bgColor='light'>
          <Col className="col-lg-6 col-12">
            <div className="content-above mb-7">
              <SectionTitle>
               Are your maintenance costs increasing?
              </SectionTitle>
              <div className="features__item-2">
                <p>Developing and maintaining multiple point-to-point custom integrations is complex. Imagine having data in multiple ERP systems, while running several other applications such as SAP SuccessFactors, SAP Concur, SAP Ariba, or SAP CX Suite. In addition, you may have many third party applications such as Salesforce or Magento, while in the future you might want to integrate with IoT sensors as an extension of your core business processes. In most organizations, integration maturity is low. This makes it highly time-consuming to manage all these integrations and security aspects. All the custom integrations make the landscape less scalable. In the end, your maintenance costs will rise exponentially.</p>
              </div>
            </div>
          </Col>
          <Col className="col-lg-6 col-12 justify-content-center d-flex">
            <GatsbyImage className="rounded-4" image={imgAdapt} alt="man – man on a mobile– sitting on a pc"/>
          </Col>
        </Section>

        {/* 
        Inbetweener 
        */}
        <Inbetweener bgColor='dark' textColor='dark'>
          Here's what will happen if you don't change...
        </Inbetweener>
        
        
        <Section id="landscape_fragmented" bgColor='light'>
          <Col className="col-lg-6 col-12">
          <GatsbyImage className="rounded-4" image={imgCost} alt="jenga– playing jenga– playing jenga on a board"/>
          </Col>
          <Col className="col-lg-6 col-12 justify-content-center d-flex">
          <div className="content-above mb-7">
              <SectionTitle>
              Your landscape will be completely fragmented
              </SectionTitle>
              <div className="features__item-2">
                <p>The fragmentation is causing low governance and integration security. This causes many integration dependencies and makes it impossible to react quickly to fast-changing market conditions. It will be very difficult to innovate and automate current business processes or to provide real-time insight into your enterprise data. Moreover, you will need to find specialized integration consultants in an already tight labor market. These integration specialists can only focus on repeatable integration tasks instead of high-value tasks. As a result, many companies struggle to retain these employees.</p>
              </div>
            </div>
          </Col>
        </Section>


        {/* 
        Inbetweener
         */}
        <Inbetweener bgColor='dark' textColor='dark'>
          Keep the core clean
        </Inbetweener>

        {/* 
        SectionStepByStep
         */}
        <SectionStepByStep bgColor='white'
          title="So where do you start?"
          subtitle={<><p>Achieving a pure CLEAN CORE is a tough challenge for any enterprise that has many custom-code-enabled business processes. The objective of this approach is to brainstorm different aspects and set up a foundation with different methodologies that are potentially available. You need to define drivers, analyze the current state, identify suitable implementation options and define the roadmap.</p>
          <p>These 5 steps show you how to build a scalable foundation for integration.</p></>}
          checkItems={[
            "Reduce complexity by building integrations outside the core system",
            "Move your integrations to the cloud",
            "Switch to an API-led integration strategy",
            "Create an event-driven architecture with loose coupling outside the core system",
            "Create modular and interchangeable building blocks to increase flexibility",
          ]}
          showButton={false}
        />

        {/*
        * Inbetweener 
        */}
        <Inbetweener bgColor='dark' textColor='dark'>
          Move to scalable integrations
        </Inbetweener>

        <Section id="integration_suite" bgColor='light'>
          <Col className="col-lg-6 col-12">
            <div className="content-above mb-7">
              <SectionTitle>
                SAP integration suite
              </SectionTitle>
              <div className="features__item-2">
                <p>SAP integration suite is one of the foundation services that provide bedrock support for all your organizational processes. It offers a one-stop holistic solution to an extremely wide range of integration problems. From a traditional service-oriented architecture to an event-driven architecture. It is a simplified and scalable solution. You will lower your TCO and become less dependent on integration talent.</p>
                <p>You will also reduce risk due to a modular integration pattern, with proper security and interchangeable building blocks. It allows you to reduce integration complexity and increase flexibility. In addition, the integration components have little or no knowledge of each other and can act independently. They are loosely coupled. You’re able to adapt fast to this fast-changing market and its conditions. You can deploy faster, increase automation and have greater access to innovation.</p>
                <p>You will reduce maintenance and upgrade costs tremendously and can work with pre-packaged integrations.</p>
              </div>
            </div>
          </Col>
          <Col className="col-lg-6 col-12 justify-content-center d-flex">
            <GatsbyImage className="rounded-4" image={imgSuite} alt="man – man holding glasses– sitting on a pc"/>
          </Col>
        </Section>

        {/*
        SCHEDULE ASSESSMENT
        */}
        <SectionScheduleAssessment bgColor='dark' topics='services'
          title="Start with an Integration exploration and advisory session based on your current architecture"
          subtitle="This is what we will cover during our first (online) meeting."
          items={[]}
          buttonText='Plan an exploration session'
        />
       
        <Footer />
      </>
  );
};

export default Home;

export const query = graphql`
query Home {
  imageHero: file(relativePath: { eq: "bg_innovation-finance-concept_1920x1280.jpg" }) {
    childImageSharp {
      gatsbyImageData(
        width: 2000
        height: 720
        quality: 50
        placeholder: BLURRED
        webpOptions: { quality: 80 }
      )
    }
  }
  imageAdapt: file(relativePath: { eq: "confident-mature-man-using-smart-phone-while-sitting-office-desk_1200x800.jpg" }) {
    childImageSharp {
      gatsbyImageData(
        width: 600
        quality: 50
        placeholder: BLURRED
        webpOptions: { quality: 70 }
      )
    }
  }
  imageCost: file(relativePath: { eq: "planning-risk-strategy-business-businessman-gambling-placing-wooden-block-tower_1200x800.jpg" }) {
    childImageSharp {
     gatsbyImageData(
       width: 400
       height: 650
       quality: 50
       placeholder: BLURRED
       webpOptions: { quality: 70 }
     )
   }
 }
 imageSuite: file(relativePath: { eq: "confident-mature-man-looking-camera-while-sitting-office-desk_1200x800.jpg" }) {
    childImageSharp {
     gatsbyImageData(
       width: 600
       quality: 50
       placeholder: BLURRED
       webpOptions: { quality: 70 }
     )
   }
 }
}
`