import React from 'react';

const SectionTitle = ({
   preTitle='',
   small=false,
   align="left",
   classes='',
   children
}) => {
   
   return (
      <>
         <div className={`section__title section__title-h2 mb-5 ${classes}`}>
            <div>{preTitle}</div>
            <h2 className={`${small && 'fs-1'} text-${align}`}>{children}</h2>
         </div>
      </>
   );
};

export default SectionTitle;