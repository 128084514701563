import React from 'react';
import { Row, Col, Nav, Tab } from 'react-bootstrap';

const TabsIcon = ({
   textColor="light",
   tabs=[],
   defaultActiveKey=''
}) => {

  return (
    <Tab.Container id="left-tabs" defaultActiveKey={defaultActiveKey} >
      <Row>
        <Col lg={4} className="mb-3">
          <Nav variant="pills" className="services__nav wow fadeInUp" data-wow-delay=".4s">
            {tabs.map((t,index) => 
              <Nav.Item key={`nav-${index}`} className="mb-3">
                <Nav.Link eventKey={t.key}><i> {t.icon} </i> {t.label}</Nav.Link>
              </Nav.Item>  
            )}
          </Nav>
        </Col>
        <Col lg={8}>
          <Tab.Content className="features__item-2 grey-bg-18">
            <div className="tab-content" id="services-content">
              {tabs.map((t,index) => 
                <Tab.Pane key={`nav-${index}`} eventKey={t.key}>
                  {t.content}
                </Tab.Pane>  
              )}
            </div>
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>
  );
};

export default TabsIcon