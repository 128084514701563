import * as React from 'react';
import { useGlobalContext }from '../../context/GlobalContext';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header';
import HeroSection from '../../components/HeroSection';
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";
import { Row, Col } from "react-bootstrap";
import Seo from "../../components/Seo";
import Section from '../../components/Section';
import SectionTitle from '../../components/Section/SectionTitle';
import SectionStepByStep from '../../components/SectionStepByStep';
import SectionScheduleAssessment from '../../components/SectionScheduleAssessment/SectionScheduleAssessment';
import Inbetweener from '../../components/Inbetweener';
import SingleCount from '../../components/SingleCount';

const Home = ({pageContext, data, location}) => {

  // init page metadata
  const { pages } = useGlobalContext();
  const thisPage = pages.getByPath(location.pathname);

  // retrieve bg image
  const {
    imageHero,
    imageLeadToCash,
    imageEventMesh,
    imageIntegrationSuite,
    imageSolution
   } = data

  const imgHero = getImage(imageHero);
  const imgLeadToCash = getImage(imageLeadToCash);
  const imgEventMesh = getImage(imageEventMesh);
  const imgIntegrationSuite = getImage(imageIntegrationSuite);
  const imgSolution = getImage(imageSolution);
  
  // get breadcrumbs
  const {breadcrumb: { crumbs }} = pageContext

  return (
      <>
        <Seo title={thisPage?.Title} description={thisPage?.Description}/>

        <Header />

        {/* 
        HERO SECTION
        */}
        <HeroSection 
            title={thisPage?.Pagetitle}
            titleFullWidth={true}
            bgImage={imgHero}
            bgImagePosition="bottom"
            breadcrumbs={crumbs}
        />

        <Section id="our-customer" bgColor='light' verticalAlign='start'>
          <Col className="col-lg-5 col-12">
            <div className="content-above mb-7">
              <SectionTitle small>
                Our customer
              </SectionTitle>
              <p>
                The customer we helped in this use case is a global leader in health and nutrition, applying bioscience to improve the health of people, animals, and the planet with the purpose to create brighter lives through developing products and solutions that address some of the world’s biggest challenges.
              </p>
            </div>
          </Col>
          <Col className="col-lg-7 col-12">
              <Row className="align-items-stretch">
                <Col className="col-lg-6 col-12 mb-3">
                  <div className="features__item-2 h-100">
                    <p>Industry</p>
                    <h3>Health, nutrition, bioscience</h3>
                  </div>
                </Col>
                <Col className="col-lg-6 col-12 mb-3">
                  <div className="features__item-2 h-100">
                    <p>Size</p>
                    <h3>Large enterprise</h3>
                  </div>
                </Col>
                <div className="w-100"></div>
                <Col className="col-lg-6 col-12 mb-3">
                  <div className="features__item-2 h-100">
                    <p>Number of employees</p>
                    <h3>More than 21k employees worldwide</h3>
                  </div>
                </Col>
                <Col className="col-lg-6 col-12 mb-3">
                  <div className="features__item-2 h-100">
                    <p>IT Landscape</p>
                    <h3>SAP S/4 HANA public cloud, Salesforce, Magento</h3>
                  </div>
                </Col>
              </Row>
          </Col>
        </Section>
        
        <Section id="use-case" bgColor='dark'>
          <Row>
            <Col className="col-lg-5 col-12">
              <div className="content-above mb-7">
                <SectionTitle>
                Use case: <br />
                Lead to Cash
                </SectionTitle>
                <div>
                  <p>Enable the lead to cash process by real-time integration of a leading CRM system (Salesforce), and a leading e-commerce platform (Magento) with S/4 HANA Public Cloud.</p>
                </div>
              </div>
            </Col>
            <Col className="col-lg-7 col-12 justify-content-center d-flex">
              <GatsbyImage style={{width:"auto", margin:"auto"}} image={imgLeadToCash} alt="icons showing a Lead to Cash flow with SAP"/>
            </Col>
          </Row>
          <Row className="row counter__area counter__area-2 align-items-stretch">
            <Col className="col-lg-4 col-12">
              <div className="features__item-2">
                <SingleCount counter={8} duration={1} title="months project duration" color="blue-2-color" />
              </div>
            </Col>
            <Col className="col-lg-4 col-12">
              <div className="features__item-2">
                <SingleCount counter={2} duration={1} title="Aarini consultants involved" color="green-4-color" />
              </div>
            </Col>
            <Col className="col-lg-4 col-12">
              <div className="features__item-2">
                <SingleCount counter={5} duration={1} title="Real-time complex workflows" color="orange-color" />
              </div>
            </Col>
          </Row>
        </Section>
        
        <Section id="challenges" bgColor='light'>
          <SectionTitle align='center'>
            Challenges of the customer
          </SectionTitle>
          <Row>
            <Col ClassName="col-lg-4 col-12">
              <div className="features__item-2 h-100">
                <p className="fs-3">Complex systems, applications, and databases do not allow for a seamless communication and efficient decision-making.</p>
              </div>
            </Col>
            <Col ClassName="col-lg-4 col-12">
              <div className="features__item-2 h-100">
                <p className="fs-3">Handling increased loads without disruptions or resource inefficiencies is extremely difficult.</p>
              </div>
            </Col>
            <Col ClassName="col-lg-4 col-12">
              <div className="features__item-2 h-100">
                <p className="fs-3">Relying on old, rigid, predefined workflows prevents adapting to rapidly changing market conditions and customer demands.</p>
              </div>
            </Col>
          </Row>
        </Section>

        <Section id="solution" bgColor='dark' verticalAlign='start'>
          <Col className="col-lg-5 col-12">
            <div className="content-above mb-7">
              <SectionTitle small>
                Solution
              </SectionTitle>
              <div>
                  <p>We have successfully implemented a solution to address the challenges faced by our customer. Leveraging SAP Event Mesh, we provided a unified, event-driven architecture that streamlines real-time data integration, ensures scalable operations with cloud-native technology, and empowers agile, responsive business processes. Our solution enhances decision-making, reduces operational disruptions, and strengthens competitiveness in dynamic markets.</p>
                </div>
            </div>
          </Col>
          <Col className="col-lg-7 col-12">
              <Row className="align-items-stretch">
                <Col className="col-xl-6 col-12 mb-3">
                  <div className="features__item-2 h-100 wow">
                    <GatsbyImage className="mb-4" image={imgIntegrationSuite} alt="Icon representing SAP Integration Suite" />
                    <h3>SAP Integration Suite</h3>
                  </div>
                </Col>
                <Col className="col-xl-6 col-12 mb-3">
                  <div className="features__item-2 h-100">
                    <GatsbyImage className="mb-4" image={imgEventMesh} alt="Icon representing SAP Mesh Events" />
                    <h3>SAP event mesh</h3>
                  </div>
                </Col>
              </Row>
          </Col>
        </Section>

        <Section id="solution" bgColor='light'>
          <GatsbyImage image={imgSolution} alt="Icon representing SAP integrations" style={{width:"auto", margin:"auto"}}/>
        </Section>

        {/* 
        Inbetweener
         */}
        <Inbetweener bgColor='dark' textColor='dark'>
          Move to event-driven scalable integrations
        </Inbetweener>

        {/* 
        SectionStepByStep
         */}
        <SectionStepByStep bgColor='white'
          title="Benefits of SAP Integration Suite and Event Mesh"
          checkItems={[
            "SAP Integration Suite helps in building a robust, holistic, and future-proof integration platform. It allows for faster integrations and can address a wide range of integration challenges, be it event-driven architecture, service-oriented architecture, API centric integration, EDI integration, or API governance, and enables quicker integration with non-SAP products.",
            "SAP Event Mesh's cloud-native design allows our customer to dynamically scale their event-driven applications and messaging infrastructure, adapting to changing demands effortlessly. This ensures optimal performance while minimizing downtime and operational costs.",
            "SAP Event Mesh on business processes enables agile and responsive event-driven processes, organizations can automate workflows, adapt swiftly to evolving needs, and maintain a competitive edge in dynamic markets.",
          ]}
          showButton={false}
        />
         
        {/*
        SCHEDULE SESSION
        */}
        <SectionScheduleAssessment 
          bgColor='dark' 
          topics='sap-btp'
          title="Interested? Schedule an SAP BTP exploration and advisory session"
          subtitle="This is what we will cover during our first (online) meeting."
          items={[]}
          buttonText='Plan an exploration and advisory session'
        />
        
        <Footer />
      </>
  );
};

export default Home;

export const query = graphql`
  query Home {
    imageHero: file(relativePath: { eq: "bg_businesswoman-sitting-office-3d_1920x1280.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 2000
          height: 720
          quality: 50
          placeholder: BLURRED
          webpOptions: { quality: 80 }
        )
      }
    }
    imageLeadToCash: file(relativePath: { eq: "sap-diagram-lead-to-cash.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 600
          quality: 50
          placeholder: BLURRED
          webpOptions: { quality: 70 }
        )
      }
    }
    imageEventMesh: file(relativePath: { eq: "sap_icon_event mesh.png" }) {
      childImageSharp {
        gatsbyImageData(
          height: 80
          quality: 50
          placeholder: BLURRED
          webpOptions: { quality: 70 }
        )
      }
    }
    imageIntegrationSuite: file(relativePath: { eq: "sap_icon_integration_suite.png" }) {
        childImageSharp {
        gatsbyImageData(
          height: 80
          quality: 50
          placeholder: BLURRED
          webpOptions: { quality: 70 }
        )
      }
    }
    imageSolution: file(relativePath: { eq: "use-case-schema-event-driven-lead-to-cash.png" }) {
        childImageSharp {
        gatsbyImageData(
          width: 1200
          quality: 50
          placeholder: BLURRED
          webpOptions: { quality: 70 }
        )
      }
    }
  }`