import React, { useState } from "react";
import Button from "../Button";
import { Col } from "react-bootstrap";
import { useGlobalContext } from "../../context/GlobalContext";

const ContactFormExplore = () => {

    const { variables } = useGlobalContext();
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [jobTitle, setJobTitle] = useState("");
    const [phone, setPhone] = useState("");
    const [company, setCompany] = useState("");
    const [country, setCountry] = useState("");
    const [consent, setConsent] = useState(true);
    const [response, setResponse] = useState("");
    
    const handleSubmit = async (e) => {    
        e.preventDefault();
        const body = {
            formLocation: "Explore and advisory session",
            name,
            email,
            phone,
            jobTitle,
            company,
            country,
            consent
        }
        console.log(body)
        try {
            let res = await fetch("/api/contact", {
                method: "POST",
                body: JSON.stringify(body),
            });
            if (res.status === 200) {
                setName("");
                setEmail("");
                setPhone("");
                setJobTitle("");
                setCompany("");
                setCountry("");
                setConsent(false);
                setResponse("Thank you for your interest. We will get back to you within 24 hours.");
            } else {
                setResponse(<>
                Sorry, some error occurred. We couldn't save your personal details. Please try it again later, or send us an email to <a href={`mailto:${variables.get('Email info')}`} target="_blank" rel="noreferrer">{variables.get('Email info')}</a>
                <br />
                <br />
                <Button variant="transparent" size="sm" OnClick={()=>setResponse("")}>Go back</Button>
                </>);
            }
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <>
            {response ? <div>{response}</div> :
                <Col className="contact__form w-100 col-12 rounded-4 p-4" style={{border:"thin solid silver"}}>
                    <form onSubmit={handleSubmit}>
                        <div className="mb-4">Let's Connect. Share your contact information and we'll get back to you to schedule a session.</div>
                        <input type="text" value={name} onChange={(e) => setName(e.target.value)} required placeholder="Your Name *" />
                        <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required placeholder="Email address *" />
                        <input type="text" value={phone} onChange={(e) => setPhone(e.target.value)} placeholder="Phone number" />
                        <input type="text" value={jobTitle} onChange={(e) => setJobTitle(e.target.value)} placeholder="Job title" />
                        <input type="text" value={company} onChange={(e) => setCompany(e.target.value)} required placeholder="Company *" />
                        <input type="text" value={country} onChange={(e) => setCountry(e.target.value)} placeholder="Country" />
                        <div className="my-4">
                            <label htmlFor="consent"><input required type="checkbox" name="consent" id="consent" value={consent} onChange={(e) => setConsent(e.target.value)} /><span className="ms-3" style={{fontSize:"1rem"}}>I agree to receive communication from Aarini. [We never share your personal data with third parties.]</span></label>
                        </div>
                        <Button variant="primary" type="submit">Send</Button>
                    </form>
                </Col>
            }
        </>
    );
};

export default ContactFormExplore;
