import * as React from 'react';
import { useGlobalContext } from '../../../context/GlobalContext';
import Footer from '../../../components/Footer/Footer';
import Header from '../../../components/Header';
import HeroSection from '../../../components/HeroSection';
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";
import { Col } from "react-bootstrap";
import Seo from "../../../components/Seo";
import Section from '../../../components/Section';
import SectionTitle from '../../../components/Section/SectionTitle';
import SectionStepByStep from '../../../components/SectionStepByStep';
import SectionScheduleAssessment from '../../../components/SectionScheduleAssessment/SectionScheduleAssessment';
import SectionContentRight from '../../../components/Section/SectionContentRight/SectionContentRight';
import SectionJoinCommunity from '../../../components/SectionJoinCommunity';
import Inbetweener from '../../../components/Inbetweener';
import Accordion from '../../../components/Accordion';

const Home = ({pageContext, data, location}) => {

  // init page metadata
  const { pages } = useGlobalContext();
  const thisPage = pages.getByPath(location.pathname);

  // retrieve bg image
  const {
    imageCost,
    imageAdapt, 
    imageHero,
    imageSuite,
    imageScore
   } = data

  const bgImage = getImage(imageHero);
  const thumbScore = getImage(imageScore);
  const imgAdapt = getImage(imageAdapt);
  const imgCost = getImage(imageCost);
  const imgSuite = getImage(imageSuite);
  
  // get breadcrumbs
  const {breadcrumb: { crumbs }} = pageContext

  return (
      <>
        <Seo title={thisPage?.Title} description={thisPage?.Description}/>

        <Header />

        {/* 
        HERO SECTION
        */}
        <HeroSection 
            title={thisPage?.Pagetitle}
            titleFullWidth={true}
            bgImage={bgImage}
            bgImagePosition="bottom"
            breadcrumbs={crumbs}
        />

        <Section id="maintenanace_cost" bgColor='light'>
          <Col className="col-lg-6 col-12">
            <div className="content-above mb-7">
              <SectionTitle>
                How complex are your current integrations?
              </SectionTitle>
              <div className="features__item-2">
                <p>Reducing the complexity of the integration landscape is a utopia, but we should aim for an increased flexibility of the integration landscape. However point-to-point integration tends to build up over time due to sudden changes in business requirements.</p>
              </div>
            </div>
          </Col>
          <Col className="col-lg-6 col-12 justify-content-center d-flex">
            <GatsbyImage className="rounded-4" image={imgAdapt} alt="group – group discussion– group disussion in office"/>
          </Col>
        </Section>

        <Section 
          id="what-is-your-score-in-integration-maturity"
          bgColor='dark'
          thumb={thumbScore}
        >
          <SectionContentRight>
            <SectionTitle small={true}>
              What is your score in integration maturity?
            </SectionTitle>
            <Accordion 
              items={[
                {
                  header: "How robust is the integration solution?",
                  body: "Do you have a high degree of confidence in the scalability and the sustainability of the integration solution to operate at high load? Or is your integration support team constantly engaged in a cycle of identifying and resolving issues."
                },
                {
                  header: "Is the integration strategy future-proof?",
                  body: "Is the current integration solution able to handle future technologies, or do you think that it can only support legacy systems and applications?"
                },
                {
                  header: "Is the integration platform holistic/hybrid?",
                  body: "Is the current integration solution able to cater to the different integration domains (Cloud – Cloud, Cloud – On-Prem, User- Cloud, thing – Cloud), integration styles (Data Integration, Process Integration, User Integration, Things integration) and business use cases (B2B, B2G, A2A, Master data Integration, Data Replication, Data Virtualization) OR do you have separate products for each requirement?"
                },
                {
                  header: "Is there a practice of empowerment to enforce SAP best practices into the integration solution?",
                  body: "Do you have a high degree of confidence that the current Integration solution has incorporated SAP best practices and will continue to do so with the new implementation?"
                },
                {
                  header: "How vulnerable is the integration?",
                  body: "Is the integration solution vulnerable to threats? Is the data in rest and the data in motion subject to external threats?"
                },
                {
                  header: "How reliable is the error-handling, error notification and retry mechanism?",
                  body: "Is the current error-handling mechanism uniform across all integration solutions? Or is integration-specific error handling in place?"
                }
              ]}
            />
          </SectionContentRight>
        </Section>

        <Section id="landscape_fragmented" bgColor='light'>
          <Col className="col-lg-6 col-12 order-md-1 justify-content-center d-flex">
            <GatsbyImage className="rounded-4" image={imgCost} alt="jenga– playing jenga– playing jenga on a board"/>
          </Col>
          <Col className="col-lg-6 col-12 justify-content-center d-flex">
            <div className="content-above mb-7">
              <SectionTitle>
              Are you scalable enough?
              </SectionTitle>
              <div className="features__item-2">
                <p>Enterprise applications integrations are not scalable due to tight integrations (point-to-point integrations). The distributed application landscape causes high dependencies and insufficient flexibility to act quickly to fast-changing demands. It also causes more vulnerabilities within the security of your applications and data integrations. You cannot meet the demands of the business to provide real-time integrations. Poor integration error handling and tracking will occur.</p>
              </div>
            </div>
          </Col>
        </Section>
  {/* 
        Inbetweener 
        */}
        <Inbetweener bgColor='dark' textColor='dark'>
          Start thinking about a loosely coupled integrations strategy
        </Inbetweener>

        {/* 
        SectionStepByStep
         */}
         <SectionStepByStep bgColor='white'
          title="So where do you start?"
          subtitle={<><p>This is where the term <b>“composable business”</b> kicks in.</p>
          <p>The concept of the composable business is a central element in this vision. Composable business means creating an organization made from interchangeable building blocks.</p>
          <p>Achieving pure CLEAN CORE is a tough challenge for any enterprise that has many custom-code-enabled business processes.</p>
          <p>But you can start by going through these 5 steps:</p></>}
          checkItems={[
            "Learn how SAP BTP helps you to design a platform that is future-proof and holistic.",
            "Find out here how Event Mesh and API Management help you to realize a future-proof integration landscape.",
            "Learn about the approach for switching to real-time integrations between SAP and non-SAP.",
            "Improve the integration maturity of your organization by constantly innovating with the latest integration solutions.",
            "Ensure that the integration solution has SAP best practices in its DNA.",
            
          ]}
          showButton={false}
        />


       {/*
        SCHEDULE ASSESSMENT
        */}
        <SectionScheduleAssessment bgColor='dark' topics='services'
          title="Start with an Integration exploration and advisory session."
          subtitle="This is what we will cover during our first (online) meeting."
          items={[]}
          buttonText='Plan an exploration session'
        />
    
         {/*
         Inbetweener 
        */}

        <Inbetweener bgColor='light' textColor='dark'>
          Move to scalable integrations
        </Inbetweener>

        <Section id="integration_suite" bgColor='dark'>
          <Col className="col-lg-6 col-12">
            <div className="content-above mb-7">
              <SectionTitle>
              A one-stop holistic solution
              </SectionTitle>
              <div className="features__item-2">
                <p>SAP integration suite is one of the foundation services that provides bedrock support for all your organizational processes. It offers a one-stop holistic solution to an extremely wide range of integration problems. </p>
                <p>From a traditional service-oriented architecture to an event-driven architecture. It is an API-led strategy for managing complex B2B scenarios, with many unique, distinctive features including support for IoT scenarios, plug-and-play support for many integration problems and tight governance of inter and intra-company integration solutions or centralized API discovery feature, a guaranteed external threat mitigation feature and AI-powered mapping proposals. </p>
                <p>All these continuous PLUS SAP innovations and deep investments in the product provide a high degree of confidence to customers to embark on this journey of continuous and rapid improvement and innovation.</p>
              </div>
            </div>
          </Col>
          <Col className="col-lg-6 col-12 justify-content-center d-flex">
            <GatsbyImage className="rounded-4" image={imgSuite} alt="man – man holding glasses– sitting on a pc"/>
          </Col>
        </Section>

        
        {/*
         SectionJoinCommunity
          */}
        <SectionJoinCommunity
          title="Subscribe and join 450 like-minded professionals in the “SAP BTP Community”"
          subtitle='Here are the latest questions from the community about simplifying and scaling your integrations to adapt to fast-changing market conditions.'
          checkItems={[
            "How does SAP BTP lower the dependency on individual developers?",
            "How can SAP BTP remove my inflexible tight integrations?",
            "How does SAP BTP help me to become future-proof?",
          ]}       
        />
        
        <Footer />
      </>
  );
};

export default Home;

export const query = graphql`
query Home {
  imageHero: file(relativePath: { eq: "bg_innovation-finance-concept_1920x1280.jpg" }) {
    childImageSharp {
      gatsbyImageData(
        width: 2000
        height: 720
        quality: 50
        placeholder: BLURRED
        webpOptions: { quality: 80 }
      )
    }
  }
  imageAdapt: file(relativePath: { eq: "teamwork-gets-business-done-group-businesspeople-going-some-plans-together_1200x1798.jpg" }) {
    childImageSharp {
      gatsbyImageData(
        width: 400
        quality: 50
        placeholder: BLURRED
        webpOptions: { quality: 70 }
      )
    }
  }
  imageScore: file(relativePath: { eq: "man-touching-sad-pain-scale-icons-virtual-screen-interface-medical-technology-futuristic-concept_1200x664.jpg" }) {
    childImageSharp {
      gatsbyImageData(
        width: 400
        height:600
        quality: 50
        placeholder: BLURRED
        webpOptions: { quality: 70 }
      )
    }
  }
  imageCost: file(relativePath: { eq: "planning-risk-strategy-business-businessman-gambling-placing-wooden-block-tower_1200x800.jpg" }) {
    childImageSharp {
     gatsbyImageData(
       width: 400
       height:600
       quality: 50
       placeholder: BLURRED
       webpOptions: { quality: 70 }
      )
    }
  }
  imageSuite: file(relativePath: { eq: "serious-about-their-business-portrait-diverse-group-businesspeople-sitting-office_1200x948.jpg" }) {
    childImageSharp {
     gatsbyImageData(
       width: 600
       quality: 50
       placeholder: BLURRED
       webpOptions: { quality: 70 }
     )
   }
 }
}
`