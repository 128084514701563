import * as React from 'react';

import { useGlobalContext } from '../context/GlobalContext';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header';
import HeroSection from '../components/HeroSection';
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";
import { Col, Row } from "react-bootstrap"
import Seo from "../components/Seo";
import Section from '../components/Section';
import SectionTitle from '../components/Section/SectionTitle';
import SectionFeature from '../components/SectionFeature';
import Inbetweener from '../components/Inbetweener';
import ListCheck from '../components/ListCheck';
import ListCheckItem from '../components/ListCheckItem';
import ReferenceLogos from '../components/ReferenceLogos';
import { device } from '../tools/breakpoints';
import styled from 'styled-components';
import ModalJoinCommunity from '../components/ModalJoinCommunity/ModalJoinCommunity';

// icons
import iconIntelligence from '../assets/icons/solid/041-intelligence.svg'
import iconTouch from '../assets/icons/solid/043-touch.svg'
import iconIdea from '../assets/icons/solid/045-idea.svg'
import iconSharing from '../assets/icons/solid/036-sharing.svg'
import iconRoute from '../assets/icons/solid/031-route.svg'
import iconChat from '../assets/icons/solid/019-conversation.svg'

const ColText = styled.div`

  @media ${device.lg} {
    columns: 2;
    column-gap: 4rem;
  }
`

const Home = ({pageContext, data, location}) => {

  // init page metadata
  const { pages } = useGlobalContext();
  const thisPage = pages.getByPath(location.pathname);

  // retrieve bg image
  const { 
    imageHero,
    imageTopics
   } = data

  const bgImage = getImage(imageHero);
  const imgTopics = getImage(imageTopics);
  
  
  // get breadcrumbs
  const {breadcrumb: { crumbs }} = pageContext

  return (
      <>
        <Seo title={thisPage?.Title} description={thisPage?.Description}/>

        <Header />

        {/* 
        HERO SECTION
        */}
        <HeroSection 
            title={thisPage?.Pagetitle}
            titleFullWidth={true}
            subtitle="Meet 250 peers and discuss Business Technology Platform(BTP) strategy"
            bgImage={bgImage}
            breadcrumbs={crumbs}
        />
        
        <Section id="sap-most-used-services" bgColor='light' verticalAlign='start'>
          <Col className="col-lg-5 col-12">
            <div className="content-above mb-7">
              <SectionTitle>
                This is what you can expect
              </SectionTitle>
              <ModalJoinCommunity />
            </div>
          </Col>
          <Col className="col-lg-7 col-12">
              <Row className="align-items-stretch">
                <Col className="col-xl-6 col-12 mb-3">
                  <div className="features__item-2 h-100">
                    <img className="mb-3" width="48px" src={iconIntelligence} alt="Icon" />
                    <h4>Meet peers of enterprise organizations on every level</h4>
                  </div>
                </Col>
                <Col className="col-xl-6 col-12 mb-3">
                  <div className="features__item-2 h-100">
                    <img className="mb-3" width="48px" src={iconTouch} alt="Icon" />
                    <h4>Build new relationships and learn faster</h4>
                  </div>
                </Col>
                <div className="w-100"></div>
                <Col className="col-xl-6 col-12 mb-3">
                  <div className="features__item-2 h-100">
                    <img className="mb-3" width="48px" src={iconIdea} alt="Icon" />
                    <h4>Learn from others while exploring innovations</h4>
                  </div>
                </Col>
                <Col className="col-xl-6 col-12 mb-3">
                  <div className="features__item-2 h-100">
                    <img className="mb-3" width="48px" src={iconSharing} alt="Icon" />
                    <h4>Share knowledge and experiences in round-table sessions, podcasts, offline and online events</h4>
                  </div>
                </Col>
              </Row>
          </Col>
        </Section>
        
        <ReferenceLogos title ='These organizations already joined the community' bgColor='dark' />

        <Section id="what-is-btp" bgColor='light' verticalAlign='start'>
          <Col className="col-lg-6 col-12">
            <div className="content-above mb-7">
              <SectionTitle>
                Discover how others organize the future
              </SectionTitle>
              <div className="col-12 col-lg-10 mb-5">The world is changing rapidly for businesses, so you must respond to this change to keep up with market and customer demands.</div>
              <ModalJoinCommunity />
            </div>
          </Col>
          <Col className="col-lg-6 col-12">
            <div className="features__item-2 justify-content-center d-flex">
              <img className="mb-3 w-100" src={iconRoute} alt="Icon" />
            </div>
          </Col>
        </Section>

        {/*
        INBETWEENER
        */}
        <Inbetweener bgColor='dark' textColor='dark' smallText>
          83% of global IT professionals surveyed said improving enterprise integration is a top priority for the next one to two years.
        </Inbetweener>
        
        <Section>
          <ColText>
            <p>In this digital world, it’s not enough for an enterprise to decide to make <b>rapid changes to business processes</b> or to transform completely. <b>Businesses are dependent on technology</b>, especially if they want to create differentiators in the market using machine learning or robotic processing. You need a platform that provides all the necessary products and services to quickly join forces with the business to make necessary <b>decisions focusing on innovation and transformation</b>.</p>
            <p><b>SAP Business Technology Platform</b> (SAP BTP) is one such platform that provides the technical foundation for businesses to change and adapt quickly. It’s the <b>foundation for an intelligent enterprise</b>.</p>
            <p>But we understand that there are many challenges when starting with BTP. Because we received a lot of questions we introduced a community where you <b>learn from each other</b> in meetups. You can learn in a personal way from like-minded peers in the market.</p>
          </ColText>
        </Section>

        <SectionFeature 
          verticalAlign='start'
          bgColor='dark'
          title="For whom is this interesting?"
          image={<img className="mb-4" src={iconChat} alt="Icon" />}
          text={<><p>Do you work with SAP and want to know how other enterprise organizations have integrated SAP Business Technology Platform. Do you want to learn, talk and share knowledge with like-minded people like Data Analysts, Integration Specialists, Application Managers, Data Experts, Head of IT etc… who are in the process of transitioning to a future-proof and sustainable enterprise, then you are most welcome.</p><p>The community offers numerous meetings where you can learn from others about their challenges, You can talk with them, share your experience or just listen. It can be anything as long as we grow together to the next level.</p></>}
        />

        <Section id="community-topics" bgColor='light'>
          <Col className="col-lg-6 col-12 order-1">
            <div className="content-above mb-7">
              <SectionTitle small>
                These are just some of the topics we cover in the meetups
              </SectionTitle>
              <ListCheck>
                <ListCheckItem>How to gain a competitive advantage on SAP Business Technology Platform.</ListCheckItem>
                <ListCheckItem>How SAP BTP and vital components of running a cleaner sustainable enterprise.</ListCheckItem>
                <ListCheckItem>How to reduce costs using SAP BTP.</ListCheckItem>
                <ListCheckItem>Integrations and strategies.</ListCheckItem>
                <ListCheckItem>Deep dive into the various components of SAP BTP.</ListCheckItem>
              </ListCheck>
              <ModalJoinCommunity buttonClasses="mt-5"/>
            </div>
          </Col>
          <Col className="col-lg-6 col-12 justify-content-center d-flex">
            <GatsbyImage className="rounded-4" image={imgTopics} alt="man – thinking – man on a pc"/>
          </Col>
        </Section>

        <Footer/>
      </>
  );
};

export default Home;

export const query = graphql`
query Home {
  imageHero: file(relativePath: { eq: "bg_event_people_sitting_speaker_1920x609.jpg" }) {
    childImageSharp {
      gatsbyImageData(
        width: 2000
        quality: 50
        placeholder: BLURRED
        webpOptions: { quality: 70 }
      )
    }
  }
  imageTopics: file(relativePath: { eq: "business_woman_okey_gesture.png" }) {
    childImageSharp {
      gatsbyImageData(
        width: 600
        quality: 90
        placeholder: NONE
        webpOptions: { quality: 70 }
      )
    }
  }
}
`