import React, { useEffect, useState } from 'react';
//import BackgroundImage from 'gatsby-background-image';
import { BgImage } from "gbimage-bridge";
import Button from '../Button';
import { Container, Row, Col } from "react-bootstrap";
import styled from 'styled-components';
import { device } from '../../tools/breakpoints';
import Breadcrumbs from '../Breadcrumbs';

const SectionTitles = styled.div`
   h1, p {}
      text-align: center;

      @media ${device.lg} {
         text-align: left;
      }
   }
`

const HeroSection = ({
   bgImage=null,
   bgImagePosition='center',
   BgShapes='',
   title="Some title",
   titleFullWidth=false,
   subtitle=null,
   breadcrumbs=false,
   light=true,
   button=false,
   buttonScrollElement=null,
   children
}) => {
   
   const [image, setImage] = useState('');
   const [shapes, setShapes] = useState('');
   useEffect(()=>{
      setImage(bgImage)
      setShapes(BgShapes)
   },[bgImage, BgShapes])

   return (
      <>
         <BgImage 
            className="hero__area p-relative overflow-hidden"
            Tag="section"
            image={image}
            preserveStackingContext
            style={{backgroundPosition:bgImagePosition}}
         >
            {shapes}
            <div className="hero__item hero__height d-flex align-items-center">
               <Container>
                  <Row>
                     <Col className={`${titleFullWidth ? 'col-12 col-lg-11' : 'col-xxl-8 col-xl-8 col-lg-10'} d-flex align-items-center`}>
                        <div className={`hero__content ${light && 'text-white'}`}>
                           {breadcrumbs && <Breadcrumbs className="breadcrumbs" parts={breadcrumbs}/>}
                           <SectionTitles>
                              <h1>{title}</h1>
                              {subtitle && <div className="fs-2 font-family-secondary"><b>{subtitle}</b></div>}
                           </SectionTitles>
                        </div>
                     </Col>
                     <Col className="justify-content-center col-12">
                        {button && 
                           <div className="d-flex justify-content-center">
                              <Button
                                 href={`#${buttonScrollElement}`}
                                 variant="start"
                              >
                                 start here
                              </Button>
                           </div>
                        }
                     </Col>
                  </Row>
                  <Row>
                     {children}
                  </Row>
               </Container>
            </div>
         </BgImage>
      </>
   );
};

export default HeroSection;