import React from 'react';
import { Link } from 'gatsby';
import { Button as BootstrapButton } from 'react-bootstrap';

const Button = ({
   type='button',
   variant="primary",
   to='',
   href=null,
   classes='',
   size='md',
   OnClick,
   target='',
   children
}) => {
   
   let btnClasses = ''
   
   switch (variant) {
      case "transparent":
         btnClasses = 'z-btn z-btn-border' 
         break;
      
      case "white":
         btnClasses = 'z-btn z-btn-white' 
         break;
      
      case "white-2":
         btnClasses = 'z-btn z-btn-white z-btn-white-2' 
         break;
      
      case "gradient-border":
         btnClasses = 'z-btn z-btn-gradient-border'
         break;
      
      case "start":
         btnClasses = 'z-btn z-btn-gradient-border z-btn-gradient-border-round'
         break;

      case "link-btn":
         btnClasses = 'link-btn'
         break;
         
      default:
         btnClasses = 'z-btn'
         break;
   }

   // add size
   switch (size) {
      case 'sm':
         btnClasses += ' z-btn-1 '
         break;
      
      case 'lg':
            btnClasses += ' z-btn-3 '
            break;

      default:
         btnClasses += ' z-btn-2 '
         break;
   }
   // add custom btn classes
   btnClasses += `${classes || classes}`
   
   return (
      <>
         {href && <a href={href} className={btnClasses} onClick={OnClick} target={target}>{children}</a>}
         {to && <Link to={to} className={btnClasses} onClick={OnClick}>{children}</Link>}
         {(!href && !to) && <BootstrapButton type={type} className={btnClasses} onClick={OnClick}>{children}</BootstrapButton>}
      </>
   );
};

export default Button;