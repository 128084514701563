import * as React from 'react';

import { useGlobalContext } from '../../context/GlobalContext';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header';
import HeroSection from '../../components/HeroSection';
import { getImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";
import Seo from "../../components/Seo";
import SectionStepByStep from '../../components/SectionStepByStep';
import SectionScheduleAssessment from '../../components/SectionScheduleAssessment/SectionScheduleAssessment';
import SectionFeature from '../../components/SectionFeature/SectionFeature';

// import icons
import sickIcon from "../../assets/icons/solid/003-sick.svg";
import devIcon from "../../assets/icons/solid/034-development.svg";
import moneyIcon from "../../assets/icons/solid/025-money.svg";

const Approach = ({pageContext, data, location}) => {

  // init page metadata
  const { pages } = useGlobalContext();
  const thisPage = pages.getByPath(location.pathname);
  
  // get breadcrumbs
  const {breadcrumb: { crumbs }} = pageContext

  // retrieve bg image
  const { 
    imageCyberSpace
   } = data

  const bgImage = getImage(imageCyberSpace);
  
  return (
      <>
        <Seo title={thisPage?.Title} description={thisPage?.Description}/>

        <Header />

        {/* 
        HERO SECTION
        */}
        <HeroSection 
            title={thisPage?.Pagetitle}
            bgImage={bgImage}
            buttonScrollElement="approach"
            breadcrumbs={crumbs}
        />

        <SectionFeature 
          title="Why you need to stop rigid ABAP coding within the core today"
          image={<img className="mb-4" src={sickIcon} alt="Icon" />}
          text="If you are still working on traditional and rigid ABAP development within the core, you will face huge dependencies and have very limited flexibility to innovate, keep the core clean and adapt to fast-changing market conditions. Without innovation, you simply cannot increase productivity and revenue."
        />

        <SectionFeature 
          title="Increase development productivity"
          image={<img className="mb-4" src={devIcon} alt="Icon" />}
          text="SAP Business Technology Platform (SAP BTP) serves as a foundation for SAP’s cloud-based solutions. Through SAP BTP, SAP can deliver services to help increase development productivity."
        />
       
        <SectionFeature 
          title="Increase scalability"
          image={<img className="mb-4" src={moneyIcon} alt="Icon" />}
          text="These services support developers by allowing them to easily store data; create great user experiences; integrate with services beyond the platform’s boundaries; perform analytics; leverage transformational technologies such as the Internet of Things (IoT), blockchain, machine learning, big data and also maintain security."
        />

        {/*
        STEP BY STEP APPROACH
        */}
        <SectionStepByStep 
          showButton={false}
          title="Step by step approach to move from A(ABAP) to B(BTP)"
          checkItems={[
            "Stop rigid ABAP coding within the core today.",
            "Write all new customizations in BTP open-source languages.",
            "Rewrite all old ABAP custom code into BTP open-source languages.",
            "Start including intelligent technologies to increase automation.",
            "Use the modular interchangeable building blocks of SAP BTP to increase scalability."
          ]}
        />

        {/*
        SCHEDULE ASSESSMENT
        */}
        <SectionScheduleAssessment 
          title="Schedule an Exploration and Advisory Session"
          subtitle="This is what we will cover during our first (online) meeting."
          items={[]}
        />

        <Footer/>
      </>
  );
};

export default Approach;

export const query = graphql`
query Home {
  imageCyberSpace: file(relativePath: { eq: "bg_digital-cyberspace-data-network-connections_1920x1080.jpg" }) {
    childImageSharp {
      gatsbyImageData(
        width: 2000
        quality: 50
        placeholder: BLURRED
        webpOptions: { quality: 70 }
      )
    }
  }
}
`