import * as React from 'react';
import { useGlobalContext } from '../context/GlobalContext';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header';
import HeroSection from '../components/HeroSection';
import { getImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";
import Seo from "../components/Seo";
import Section from '../components/Section';
import SectionTitle from '../components/Section/SectionTitle';
import SingleService from '../components/SingleService/SingleService';
import SectionContentright from '../components/Section/SectionContentRight';
import SectionSubtitle from'../components/Section/SectionSubtitle';
import SectionFeature from '../components/SectionFeature';

// icons and logos
import IconBinoculars from '../assets/icons/monochrome/015-jetpack.svg'
import IconBulb from '../assets/icons/monochrome/006-smart energy.svg'
import IconConnectivity from '../assets/icons/monochrome/040-connection.svg'
import IconTime from '../assets/icons/monochrome/003-timer.svg'
import IconDollarPercentage from '../assets/icons/monochrome/001-coin.svg'
import IconFaceArrow from '../assets/icons/monochrome/002-travel.svg'
import IconGame from '../assets/icons/monochrome/024-game console.svg'
import IconMap from '../assets/icons/monochrome/031-route.svg'
import IconProjects from '../assets/icons/monochrome/034-augmented reality.svg'

const Home = ({pageContext, data, location}) => {

  // init page metadata
  const { pages } = useGlobalContext();
  const thisPage = pages.getByPath(location.pathname);

  // retrieve bg image
  const { 
    imageHero,
    imageCommunity
   } = data

  const bgImage = getImage(imageHero);
  const imgCommunity = getImage(imageCommunity);
  
  // get breadcrumbs
  const {breadcrumb: { crumbs }} = pageContext

  return (
    <>
        <Seo title={thisPage?.Title} description={thisPage?.Description}/>

        <Header />

        {/* 
        HERO SECTION
        */}
        <HeroSection 
            title={thisPage?.Pagetitle}
            titleFullWidth={true}
            bgImage={bgImage}
            bgImagePosition="center"
            breadcrumbs={crumbs}
        />

        <SectionFeature 
            id="shaping-tomorrow-together"
            title="Shaping tomorrow together"
            text="At Aarini, we believe that our people are our most valuable asset. We are committed to creating a supportive and inclusive work environment where our team members can thrive both professionally and personally."
        />

        <Section verticalAlign='top'>
            <div className="text-center">
                <SectionTitle>The Aarini formula</SectionTitle>
                <SectionSubtitle>The way we work is guided by the six principles of the Aarini Formula.</SectionSubtitle>
            </div>
            {[
                {
                    icon: IconProjects,
                    title: 'Exciting Projects',
                    text: "We work on a diverse range of projects, from building complex software applications to implementing cutting-edge solutions for our clients. You'll have the opportunity to work on exciting projects that will challenge and enhance your skills, and contribute to meaningful technological advancements.",
                    bgColor:"light",
                },
                {
                    icon: IconBinoculars,
                    title: 'Professional Growth',
                    text: "We are committed to helping our team members grow and develop their careers. We provide regular training programs, mentorship, and opportunities for skill-building and advancement. You'll have the chance to expand your knowledge, gain new experiences, and take your career to new heights.",
                    bgColor:"light",
                },
                {
                    icon: IconConnectivity,
                    title: 'Collaborative Environment',
                    text:"Collaboration is at the heart of what we do. We foster a collaborative work environment where teamwork, open communication, and idea-sharing are encouraged. You'll have the opportunity to work with a diverse team of experts, collaborate on projects, and learn from each other.",
                    bgColor:"light",
                },
                {
                    icon: IconBulb,
                    title: 'Innovation and Creativity',
                    text:"We value innovation and creativity in everything we do. We encourage our team members to think outside the box, come up with new ideas, and challenge the status quo. You'll have the freedom to bring your unique perspective and contribute to the development of cutting-edge solutions.",
                    bgColor:"light",  
                },
                {
                    icon: IconMap,
                    title: 'Impactful Work',
                    text:"At Aarini, we believe in making a difference. Our solutions have a real impact on our clients' businesses and the IT industry as a whole. You'll have the opportunity to work on projects that have a meaningful impact and contribute to the success of our clients and our company.",
                    bgColor:"light", 
                },
                {
                    icon: IconGame,
                    title: 'Work-Life Balance',
                    text:"We understand the importance of maintaining a healthy work-life balance. We offer flexible working arrangements, generous time-off policies, and a supportive work culture that promotes work-life balance. We believe that happy and healthy team members are more productive and fulfilled in both their personal and professional lives.",
                    bgColor:"light",
                }
            ].map((s,index) => (
                <div key={`tile-${index}`} className="col-lg-4 mb-3">
                    <SingleService 
                        icon={s.icon}
                        iconHeight={30}
                        title={s.title}
                        text={s.text}
                        bgColor={s.bgColor}
                    />
                </div> 
            ))}
        </Section>

        <Section thumb={imgCommunity} id="quote" bgColor='black-bg'>       
            <SectionContentright>       
                <div className="features__item-2 text-white" >
                    <p className='fs-3 font-family-secondary'>
                        <b>
                            "Our strategy is simple: hire the best. We seek highly skilled individuals to meet our long-term technological and commercial needs, ensuring our sustained growth and success."
                        </b>
                    </p>
                    <p>
                        Harikishore Sreenivasalu<br/>CTO & Founder
                    </p>
                </div>
            </SectionContentright>
        </Section>
            
        <Section verticalAlign='top'>
            <div className="  content-under text-center mb-5  mt-8">
                <SectionTitle> Benefits offer </SectionTitle>
            </div>
            {[
                {
                    icon: IconDollarPercentage,
                    title: 'The Basics',
                    text: "Benefits that make working and living–wherever in the world you are–more harmonious. This includes things such as commute allowance, and benefits mandated by local governments.",
                    bgColor:"light",
                },
                {
                    icon: IconFaceArrow,
                    title: 'Professional Growth',
                    text: "All employees receive benefits to support their personal needs. This includes things such as language courses, and working from home setups.",
                    bgColor:"light",
                },                   
                {
                    icon: IconTime,
                    title: 'Work-Life Balance',
                    text:"We offer flexibility in your work because we understand that big life events happen. This includes following a ‘normal course of life’ approach to working setups, international work and more.",
                    bgColor:"light",    
                }
            ].map((s,index) => (<div key={`tile-${index}`} className="col-lg-4 mb-3">
                    <SingleService 
                        icon={s.icon}
                        iconHeight={30}
                        title={s.title}
                        text={s.text}
                        bgColor={s.bgColor}
                    />
                </div>
            ))}
        </Section>
        
        <SectionFeature 
            id="join-our-team"
            title="Join our team"
            text={<>We welcome open solicitations. If you are interested in joining our team at Aarini please send your CV and cover letter to <a href='mailto:hradmin@aarini.com?subject=Join%20our%20team%20at%20aarini' rel="noreferrer" target='_blank'>hradmin@aarini.com</a></>}
        />

        <Footer/>
    </>
  );
};

export default Home;

export const {query,imageCommunity} = graphql`
query Home {
    imageHero: file(relativePath: { eq: "bg_event_sap experience center_march 2023_IMG_6789 2.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 2000
            quality: 50
            placeholder: BLURRED
            webpOptions: { quality: 70 }
          )
        }
   }
   imageCommunity: file(relativePath: { eq: "aarini_ceo_harikishore_giving_speech_720x811.jpg" }) {
        childImageSharp {
            gatsbyImageData(
            width: 600
            quality: 50
            placeholder: BLURRED
            webpOptions: { quality: 70 }
            )
        }
    }
}`

