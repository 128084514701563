import { useStaticQuery, graphql } from "gatsby"

export const useVariables = () => {
    const {pages:{edges:content}} = useStaticQuery(graphql`
    {
        pages: allSharePointVariablesList {
            edges {
                node {
                    data {
                        fields {
                            Title
                            Value
                        }
                    }
                }
            }
        }
    }`)

    const get = (variable) => {
        
        let result = content.filter((item) => item.node.data.fields.Title === variable)
        result = result[0]?.node?.data?.fields?.Value || variable
    
        return result
    }
    
    return {
        get
    }
}