import React, { useEffect, useState } from 'react';
//import BackgroundImage from 'gatsby-background-image';
import { BgImage } from "gbimage-bridge";
import { Container, Row, Col } from "react-bootstrap";

const HeaderBg = ({
   bgImage=null,
   bgImagePosition='center',
   BgShapes='',
   minHeight=80
}) => {
   
   const [image, setImage] = useState('');
   const [shapes, setShapes] = useState('');
   useEffect(()=>{
      setImage(bgImage)
      setShapes(BgShapes)
   },[bgImage, BgShapes])

   return (
      <>
         <BgImage 
            className="hero__area p-relative overflow-hidden"
            Tag="section"
            image={image}
            preserveStackingContext
            style={{backgroundPosition:bgImagePosition}}
         >
            {shapes}
            <div className="hero__item d-flex align-items-center">
               <Container>
                  <Row>
                     <Col className={`col-12 col-lg-11 d-flex align-items-center`} style={{minHeight:`${minHeight}px`}}>
                     </Col>
                  </Row>
               </Container>
            </div>
         </BgImage>
      </>
   );
};

export default HeaderBg;