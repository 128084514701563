import * as React from 'react';
import { useGlobalContext } from '../context/GlobalContext';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header';
import HeroSection from '../components/HeroSection';
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";
import styled from 'styled-components';
import { device } from '../tools/breakpoints';
import Seo from "../components/Seo";
import Section from '../components/Section';
import SectionTitle from '../components/Section/SectionTitle';
import SectionContentRight from '../components/Section/SectionContentRight';
import ListCheck from '../components/ListCheck/ListCheck';
import ListCheckItem from '../components/ListCheckItem';
import Button from '../components/Button';
import Testimonials from '../components/Testimonials';
import ReferenceLogos from '../components/ReferenceLogos'
import ServicesTilesThree from '../components/ServicesTilesThree';
import SectionFeature from '../components/SectionFeature';

//icons
import IconCloudStorage from '../assets/icons/solid/016-cloud storage.svg';
import IconArrows from '../assets/icons/solid/014-arrows.svg';
import IconVisualization from '../assets/icons/solid/021-data visualization.svg';

const StyledBgShapes = styled.div`
  .hero__shape__woman {
    display: none;
    transition: none;
    animation: none;
    position: absolute;
    left: 60%;
    bottom:0;
    height: 90%;
    min-width: 700px;

    @media ${device.lg} {
      display: block;
    }
    @media ${device.xxl} {
      display: block;
    }
    @media ${device.xxxl} {
      display: block;
    }
  }
`

const Home = ({data, location}) => {

  // init page metadata
  const { pages } = useGlobalContext();
  const thisPage = pages.getByPath(location.pathname);

  // retrieve bg image
  const { 
    imageWoman, 
    imageCyberSpace,
    imageChallenges,
    imageTestimonial
   } = data

  const bgImage = getImage(imageCyberSpace);
  const bgShapeWoman = getImage(imageWoman);
  const thumbChallenges = getImage(imageChallenges);
  const avatarTestimonial = getImage(imageTestimonial);

  return (
      <>
        <Seo title={thisPage?.Title} description={thisPage?.Description}/>

        <Header />

        {/* 
        HERO SECTION
        */}
        <HeroSection 
            title={thisPage?.Pagetitle}
            bgImage={bgImage}
            BgShapes={<StyledBgShapes>
              <GatsbyImage className="hero__shape__woman" image={bgShapeWoman} alt="Smiling woman in black corporate suite holding a cup of coffee and office files" />
            </StyledBgShapes>}
            button={true}
            buttonScrollElement="our-approach"
        />
        
        <SectionFeature 
          id="our-approach"
          title="Accelerate your Digital Journey"
          text="Today, organizations are accumulating exponentially larger volumes of customer data, and it often becomes fragmented, duplicated, inconsistent, incomplete, ungoverned and out-of-date as it travels throughout the organization. We solve challenges such as: Structuring data silos, governance processes, Setup Real-time data and the integration of data."
        >
          <div className="content-under mt-6">
            <ServicesTilesThree data={
              [
                {
                  icon: IconCloudStorage,
                  title: 'Keep the core clean',
                  to: pages.getByTitle('Keep the core clean')?.Path
                },
                {
                  icon: IconArrows,
                  title: 'Move from A(ABAP) to B(BTP)',
                  to: pages.getByTitle('Move from A(ABAP) to B(BTP)')?.Path
                },
                {
                  icon: IconVisualization,
                  title: 'Gain 360˚ overview on enterprise data',
                  to: pages.getByTitle('Gain 360˚ overview on enterprise data')?.Path
                }
              ]}
            />
          </div>
        </SectionFeature>
        
        {/*
        FREQUENT CHALLENGES
        */}
        <Section 
          id="most-frequent-challenges-when-working-sap"
          bgColor='dark'
          thumb={thumbChallenges}
        >
          <SectionContentRight>
            <SectionTitle small={true}>
              Most frequent challenges for enterprise organisations when working with SAP
            </SectionTitle>
            <ListCheck classes="mb-6">
              {[
                "How does SAP BTP help to adapt quickly to fast-changing market conditions?",
                "How do I reduce my TCO?",
                "What are the benefits of SAP BTP vs traditional SAP ABAP programming?",
                "Is my integration strategy future-proof?",
                "How do I reduce complexity with my applications?"
              ].map((item, index) => <ListCheckItem key={`item-${index}`}>{item}</ListCheckItem>)}
            </ListCheck>
            <Button to={pages.getByTitle('Community').Path}>Find more inspiration here</Button>
          </SectionContentRight>
        </Section>
        
        {/* 
        REFERENCE LOGOS
        */}
        <ReferenceLogos title="We guide these enterprise organizations towards a clean core" />
        
        {/* 
        TESTIMONIALS
        */}
        <Testimonials 
          name="Frits Reneman"
          jobTitle="Principal Consultant, Cybex"
          image={avatarTestimonial} 
          company="Cybex"
          text="I was super impressed with your performance. I think you are really strong in listening to what is being said, then bring your huge amount of knowledge and experience into play in exactly the right way. I have full trust you will assure that Cybex gets the best solution for them and I’m really happy you are part of the team."
        />

        <Footer/>
      </>
  );
};

export default Home;

export const query = graphql`
query Home {
  imageWoman: file(relativePath: { eq: "corporate_woman_standing.png" }) {
    childImageSharp {
      gatsbyImageData(
        width: 700
        quality: 70
        placeholder: BLURRED
        webpOptions: { quality: 90 }
      )
    }
  }
  imageCyberSpace: file(relativePath: { eq: "bg_cyber_space_tiles_it.jpg" }) {
    childImageSharp {
      gatsbyImageData(
        width: 2000
        quality: 50
        placeholder: BLURRED
        webpOptions: { quality: 70 }
      )
    }
  }
  imageChallenges: file(relativePath: { eq: "businessmen-holding-dices-with-company-ideas_1200x800.jpg" }) {
    childImageSharp {
      gatsbyImageData(
        width: 600
        quality: 50
        placeholder: BLURRED
        webpOptions: { quality: 70 }
      )
    }
  }
  imageTestimonial: file(relativePath: { eq: "testimonial-frits reneman.png" }) {
    childImageSharp {
      gatsbyImageData(
        width: 500
        quality: 50
        placeholder: BLURRED
        webpOptions: { quality: 70 }
      )
    }
  }
}
`