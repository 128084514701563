import React, { useEffect, useRef } from 'react';

import { useGlobalContext } from '../context/GlobalContext';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header';
import HeroSection from '../components/HeroSection';
import { getImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";
import { Col } from "react-bootstrap"
import Seo from "../components/Seo";
import Section from '../components/Section';
import ListCheck from '../components/ListCheck';
import ListCheckItem from '../components/ListCheckItem';

const Home = ({pageContext, data, location}) => {

  // init page metadata
  const { pages, variables, cookieConsentBarOpen, setCookieConsentBarOpen } = useGlobalContext();
  const thisPage = pages.getByPath(location.pathname);

  // retrieve bg image
  const { 
    imageHero
   } = data

  const bgImage = getImage(imageHero);
  
  // get breadcrumbs
  const {breadcrumb: { crumbs }} = pageContext
  
  // Suppress cookie consent bar
  const current = useRef(cookieConsentBarOpen)
  useEffect(()=> {
    setCookieConsentBarOpen(false)
    return () => {
      setCookieConsentBarOpen(current)
    }
  },[current, setCookieConsentBarOpen])

  return (
      <>
        <Seo title={thisPage?.Title} description={thisPage?.Description}/>

        <Header />

        {/* 
        HERO SECTION
        */}
        <HeroSection 
            title={thisPage?.Pagetitle}
            titleFullWidth={true}
            bgImage={bgImage}
            breadcrumbs={crumbs}
        />

        <Section>
          <Col className="col-lg-8">
          <h1 className="mb-4">Protecting your privacy </h1>

          <p>This privacy statement (the "Statement") relates to aarini.com, Aarini Consulting BV, with registered office in the Netherlands, Wegalaan 4, Hoofddorp, registered in the Chamber of Commerce number 74116967 ("Aarini"). Aarini is committed to ensuring that your privacy is protected. So please read this Statement carefully to understand our views and practices regarding your personal data and how we will treat it. </p>

          <h1 className="my-4">Who is responsible for processing your personal data? </h1>

          <p>Aarini is controller regarding the processing of your personal data. Aarini processes your personal data only for the purposes and means, as further explained in this Statement. When processing your personal data, Aarini is committed to complying with applicable law, including applicable privacy legislation. </p>

          <h1 className="my-4">Why do we collect personal data about you and what do we do with it? </h1>

          <p>Personal data is any information relating to an identified or identifiable natural person, such as a name, an e-mail address or telephone number. We may collect and process the following personal data from you:  </p>

          <p>information that you email to us, such as contact details and e-mail address. The legal basis for this data processing is your consent or, insofar as not provided, our legitimate interest in processing your email inquiry and providing services or information requested by you (art. 6 par. 1 sub a and f GDPR). Please note that you may always withdraw your consent; </p>

          <p>details of your visits to our website, including but not limited to traffic data, location data and other communication data and the resources that you access. This data is collected and processed for the purpose of allowing you to use our website (establishing a connection), ensuring system security and stability in the long term, allowing our website and your connection to be optimised, as well as for internal statistical purposes. Your IP address will be evaluated for clarification and defence purposes only in the event of an attack on our website's network infrastructure or in case we suspect unauthorised or abusive use of the website. It may further be used in criminal proceedings to identify individuals and in the context of civil and criminal proceedings against such individuals, as necessary and required. The legal basis for this data processing is our legitimate interest to process your data for these purposes (art. 6 par. 1 sub f GDPR)</p>

          <p>we may also use the personal data and other information you provide in connection with your use of our website to help us improve the content and functionality of the website and to better understand our users. The legal basis for this data processing consists in our legitimate interest to process the data for these purposes (art. 6 par. 1 sub f GDPR). </p>

          <p>We use the personal data you provide in accordance with this Statement. If you provide personal data for a certain reason, we may use your personal data for that reason. For instance, if you contact us by email, we will use your personal data to answer your question or resolve your problem. If we intend to use your personal data in any manner that is not consistent with this Statement, you will be informed about that before or at the moment the personal data is collected. </p>

          <h1 className="my-4">Automated decision making </h1>

          <p>Your personal data will always be processed by a human being. Aarini will not take any decisions that may affect you personally based on automated processing. </p>

          <h1 className="my-4">How long do we keep your information?  </h1>

          <p>Your personal data will only be kept for as long as is necessary to achieve the purposes for which it was collected, or to comply with legal obligations that require us to keep it for a specific legal retention period. The retention periods are determined by considering the amount, nature and sensitivity of the personal data, the purposes for which we process your personal data and, if existing, the applicable legal requirements. For more information on our data retention policy, please contact us at info@aarini.com.  </p>

          <h1 className="my-4">To whom do we disclose your information collected through the Aarini website?  </h1>

          <p>Your information will only be disclosed to third parties if that is necessary to deal with the following purposes, namely to handle your inquiry, to provide requested services or information, or to analyse the usage pattern of the website. However, there may be other circumstances where we disclose your information to third parties to comply with any legal or regulatory obligation or request. We do not undertake marketing activities for third parties, nor do we provide information to third parties for their own marketing purposes.  </p>

          <p>Our website is hosted by Microsoft (Microsoft Ireland Operations Limited, Atrium Building Block B, Carmenhall Road, Sandyford Industrial Estate, Dublin 18, Ireland ) and Microsoft processes any information (including personal data) for the purpose of providing and maintaining the functionalities of our website.</p>

          <h1 className="my-4">Do we transfer data to recipients outside of Netherlands or the European Economic Area?  </h1>

          <p>Your personal data may be transferred to third parties (contracted service providers) located abroad for the purpose of the data processing described in this Statement. Aarini requires any such third-party companies to protect the privacy of individuals to the same extent as we do. If the level of data protection in a country does not correspond to the Swiss or European level, we shall ensure by contract (standard contractual clauses issued by the European Commission) that the protection of your personal data corresponds at all times to that of the European Economic Area, or countries that are 'white listed' by the European Union such as the Netherlands. If you would like to know to which countries your personal data is transferred, please contact us at info@aarini.com.  </p>

          <h1 className="my-4">How do we protect your data? </h1>

          <p>The security of your personal data is important to us. We collect, process and store all data in line with the regulations on personal data processing applicable in the Netherlands and the European Union. We follow generally accepted standards to protect the personal data submitted to us, both during transmission and once we receive it. No method of transmission over the internet, or method of electronic storage, is completely secure. Therefore, although we will do our best to protect your information, we cannot fully ensure the security of the data you transmitted to our website. Any information you submit is sent at your own risk. Once we have received your information, we will use strict procedures and security features to prevent unauthorised access. </p>

          <p>We take all reasonable care to prevent access to your personal data by unauthorised persons and to prevent unlawful processing, accidental loss, destruction, and damage. </p>

          <h1 className="my-4">How do we make use of cookies and tracking-tools?  </h1>

          <p>Our website uses cookies, which are small pieces of data that automatically store information on web browsers and store and receive identifiers from computers, phones and other devices. Our website makes only minimal use of cookies. </p>

          <h2>Google Analytics  </h2>

          <p>Our website uses Google Analytics, a web analysis service of Google Ireland Limited, Google Building Gordon House, 4 Barrow St, Dublin, D04 E5W5, Ireland, respectively Google Inc., 1600 Amphitheatre Pkwy, Mountain View, CA 94043-1351, USA (Google). This means that analytical cookies provided by Google are placed on our website. Based on the data collected by these cookies, Google prepares anonymous reports for us on the use of our website. The cookies will, for example, generate your computer's IP address or information on from where in the world you are accessing the website or on the referrer URL. The cookies we use help us understand how visitors use our website and to generate statistics (e.g. at what time your current visit began, whether you have been to the site before, what site referred you, what the most popular content is, the number of visitors, their geographic location), which also helps us to improve our website.  </p>

          <p>For a more detailed explanation of what the different cookies do, what information they store and for how long, information is available under the following link: <a target="_blank" rel='noreferrer' href="https://developers.google.com/analytics/devguides/collection/gajs/cookie-usage">https://developers.google.com/analytics/devguides/collection/gajs/cookie-usage</a> </p>

          <p>The information generated is transferred to the US and stored on Google servers. The IP-address will be shortened through IP anonymisation ("anonymise IP") on our website before it is transferred to Google servers in member states of the European Union or other states in the European Economic Area that are party to the agreement, as well as in the Netherlands. Pursuant to Google, the anonymised IP address will not be merged with any other data held by Google. In exceptional cases only, the complete IP address may be transferred to a Google server in the US and shortened there. In these cases, we ensure through contractual guarantees that Google complies with a sufficient level of data protection. </p>

          <p>To provide website visitors with more choice on how data is collected by Google Analytics, Google has developed the Google Analytics Opt-out Browser Add-on. The add-on communicates with the Google Analytics JavaScript (ga.js) to stop data being sent to Google Analytics. The Google Analytics Opt-out Browser Add-on does not affect usage of the website in any way. The Add-on is available under the following link: http://tools.google.com/dlpage/gaoptout?hl=en  </p>

          <p>For more information on the use of cookies and for details of data privacy and security relating to Google Analytics, please see the Google website.  </p>

          <h2>Google Tag Manager  </h2>

          <p>Our website uses Google Tag Manager, a service of Google Inc. Google Tag Manager is a solution that allows vendors and marketers to manage website tags through one interface. The Tag Manager tool is a cookie-less domain and does not collect personal information. The tool triggers other tags, which in turn may collect personal information. The Google Tag Manager does not access this information. If disabled at the domain or cookie level, it will remain disabled for all tracking tags implemented with Google Tag Manager. You can prevent tags from being set at any time by disabling cookies. </p>

          <h1 className="my-4">Disabling cookies  </h1>

          <p>If you do not wish to receive any cookies at all, you will have to change your computer settings. However, you should be aware that this might limit the extent to which you can use our website or parts of our website. </p>

          <h1 className="my-4">Links to other websites </h1>

          <p>This Statement extends only to our website and does not cover the use, provision or collection of data on any other website.  </p>

          <p>Our website may from time to time contain links to and from other websites. If you follow a link to any of these websites, please note that these websites have their own privacy policies and that we do not accept any responsibility or liability for these privacy policies. </p>

          <h1 className="my-4">Your data protection rights </h1>

          <p>You have the following rights: </p>

          <ListCheck>
            <ListCheckItem>Right to information and access to your personal data: You have the right to request access to your personal data stored by us at any time. This provides you with the opportunity to check which personal data we process about you and that the purposes and means of the processing are in accordance with applicable data protection regulations.  </ListCheckItem>
            <ListCheckItem>Right to object: You have the right to object to the processing of your personal data at any time if your personal data is used for purposes other than those necessary for the execution of an agreement or necessary for compliance with a legal obligation. </ListCheckItem>
            <ListCheckItem>Right to rectification: You have the right to have inaccurate or incomplete personal data corrected and to be informed of the correction. We will also inform the recipients of the data about the adjustments made to any incorrect data, unless such notification is impossible or involves a disproportionate effort.  </ListCheckItem>
            <ListCheckItem>Right to be forgotten: You have the right to have your personal data deleted from our records, provided that we are not required or authorised by applicable law or regulation to further process your data. </ListCheckItem>
            <ListCheckItem>Right to restrict processing: You have the right, under certain conditions, to request that the processing of your data is restricted. The right to restrict processing means that we may not (temporarily) process or change your personal data. This is the case if you dispute the correctness of the data, if in your opinion the personal data is no longer necessary for the purposes or if in our opinion the processing of your personal data by Aarini is unlawful. </ListCheckItem>
            <ListCheckItem>Right to data portability: Where permitted by applicable law or regulation you have the right to receive from us the personal data that you have provided to us, free of charge and in a readable format. This applies to users outside the Netherlands with residence in an EU or EEA member country.</ListCheckItem>
            <ListCheckItem>Right to withdraw your consent: You can withdraw your consent to certain data processing at any time with effect for the future. The withdrawal of your consent does not affect the legality of the data processing based on your consent carried out until the revocation.  </ListCheckItem>
          </ListCheck>

          <p>To request information or contact us, please use the contact information listed below. We will respond to your request to access your information within 30 days. </p>

          <p>You also have the option to file a complaint with the competent privacy authority. If you need help establishing which authority is competent, you can contact us at info@aarini.com.  </p>

          <h1 className="my-4">Changes to this Statement  </h1>

          <p>We regularly review this Statement; any changes will be included in this section of the site. We therefore ask you to consult it on a regular basis.  </p>

          <h1 className="my-4">Contact</h1>

          <p>Questions, comments and requests regarding this Statement are welcome and can be emailed to <a target="_blank" rel="noreferrer" href={`mailto:${variables.get('Email info')}`}>{variables.get('Email info')}</a> or sent to the following address:  </p>

          Aarini Consulting
          <p>{variables.get("Address NL")}</p>
          </Col>
        </Section>

        <Footer/>
      </>
  );
};

export default Home;

export const query = graphql`
query Home {
  imageHero: file(relativePath: { eq: "bg_digital-cyberspace-data-network-connections_1920x1080.jpg" }) {
    childImageSharp {
      gatsbyImageData(
        width: 2000
        quality: 50
        placeholder: BLURRED
        webpOptions: { quality: 70 }
      )
    }
  }
}`