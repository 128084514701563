import React from 'react';
import quote from "../../assets/img/icon/testimonial/quote.png";
import { GatsbyImage } from "gatsby-plugin-image";
import Section from '../Section';
import { Col } from "react-bootstrap";

const Testimonials = ({text, name, jobTitle, company, image=null}) => {

   return (
      <>
         <Section classes="testimoinal__area gradient-bg">
            <Col className="col-lg-6 col-12">
               <div className="section__title section__title-3 mb-4">
                  <span className="white-color">Testimonial</span>
                  <h2 className="white-color">{company}</h2>
               </div>
                  <div className="testimonial__item">
                     <div className="white-color">{text}</div>
                     <div className="testimonial__content d-flex align-items-center">
                        <div className="quote me-3">
                           <img src={quote} alt="quote" />
                        </div>
                        <div className="testimonial__info">
                           <h4 className="white-color">{name}</h4>
                           <div className="white-color">{jobTitle}</div>
                        </div>
                     </div>

                  </div>
            </Col>
            <Col className="col-lg-6 col-12">
               <div className='testimonial__thumb m-img text-end'>
                  <GatsbyImage image={image} alt={`Avatar of ${name}`}/>
               </div>
            </Col>
         </Section>
      </>
   );
};

export default Testimonials;