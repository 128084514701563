import * as React from 'react';

import { useGlobalContext } from '../../../context/GlobalContext';
import Footer from '../../../components/Footer';
import Header from '../../../components/Header';
import HeroSection from '../../../components/HeroSection';
import { getImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";
import Seo from "../../../components/Seo";
import Section from '../../../components/Section';
import SectionTitle from '../../../components/Section/SectionTitle';
import SectionSubtitle from '../../../components/Section/SectionSubtitle';
import SectionContentRight from '../../../components/Section/SectionContentRight';
import ListCheck from '../../../components/ListCheck';
import ListCheckItem from '../../../components/ListCheckItem';
import Button from '../../../components/Button';
import ServicesTilesThree from '../../../components/ServicesTilesThree';
import Inbetweener from '../../../components/Inbetweener';
import SectionCTA from '../../../components/SectionCTA';

//icons
import IconTCO from '../../../assets/icons/solid/040-hand.svg';
import IconApp from '../../../assets/icons/solid/031-route.svg';
import IconSap from '../../../assets/icons/solid/010-monitor.svg';

const Home = ({pageContext, data, location}) => {

  // init page metadata
  const { pages } = useGlobalContext();
  const thisPage = pages.getByPath(location.pathname);

  // retrieve bg image
  const { 
    imageCode,
    imageSolutions
   } = data
  
  const bgImage = getImage(imageCode);
  const thumbSolutions = getImage(imageSolutions);
  
  // get breadcrumbs
  const {breadcrumb: { crumbs }} = pageContext

  return (
      <>
        <Seo title={thisPage?.Title} description={thisPage?.Description}/>

        <Header />

        {/* 
        HERO SECTION
        */}
        <HeroSection 
            title={thisPage?.Pagetitle}
            titleFullWidth={true}
            bgImage={bgImage}
            button={true}
            buttonScrollElement="start-here"
            breadcrumbs={crumbs}
        >
          <div className="content-under pt-8" id="start-here">
            <ServicesTilesThree 
              titleSize='sm'
              data={[
                {
                  icon: IconTCO,
                  title: 'How to lower your application development TCO & dependencies',
                  to: pages.getByTitle('Lower application development TCO')?.Path
                },
                {
                  icon: IconApp,
                  title: 'How to transform to a solid, future-proof application integration foundation',
                  to: pages.getByTitle('Solid app integration foundation')?.Path
                },
                {
                  icon: IconSap,
                  title: 'How the new SAP application development drastically increases innovation, flexibility, and scalability',
                  to: pages.getByTitle('SAP application development')?.Path
                }
              ]}
            />
          </div>
        </HeroSection>
        
        {/*
        INBETWEENER
        */}
        <Inbetweener smallText>
          Reduce complexity by building integrations outside the core system
        </Inbetweener>

        {/*
        INNOVATIVE SOLUTIONS
        */}
        <Section 
          id="most-frequent-challenges-when-working-sap"
          bgColor='dark'
          thumb={thumbSolutions}
        >
          <SectionContentRight>
            <SectionTitle small={true}>
              Deliver highly innovative solutions
            </SectionTitle>
            <SectionSubtitle>
              Here are the latest questions from the community related to Rapid application development scalability:
            </SectionSubtitle>
            <ListCheck classes="mb-6">
              {[
                "How does SAP BTP help to adapt quickly to fast-changing market conditions?",
                "How does SAP BTP lower the dependency on individual developers?",
                "How does SAP BTP help you to deploy faster?",
              ].map((item, index) => <ListCheckItem key={`item-${index}`}>{item}</ListCheckItem>)}
            </ListCheck>
            <Button to={pages.getByTitle('Community').Path}>Join our community</Button>
          </SectionContentRight>
        </Section>
        
        {/*
        INBETWEENER
        */}
        <Inbetweener smallText>
          Create modular and interchangeable building blocks to increase flexibility
        </Inbetweener>

        {/*
        SECTION CTA
        */}
        <SectionCTA 
          title="Want to know how we can help?" 
          buttons={[
            {to: 'Approach', text: 'See our approach'}
          ]} 
          id="section-see-approach"
        />

        <Footer/>
      </>
  );
};

export default Home;

export const query = graphql`
query Home {
  imageCode: file(relativePath: { eq: "bg_businesswoman-sitting-office-3d_1920x1280.jpg" }) {
    childImageSharp {
      gatsbyImageData(
        width: 2000
        quality: 50
        placeholder: BLURRED
        webpOptions: { quality: 70 }
      )
    }
  }
  imageSolutions: file(relativePath: { eq: "businessmen-holding-dices-with-company-ideas-front-view_1200x860.jpg" }) {
    childImageSharp {
      gatsbyImageData(
        width: 600
        quality: 50
        placeholder: BLURRED
        webpOptions: { quality: 70 }
      )
    }
  }
}
`