import * as React from 'react';
import { useGlobalContext }from '../../../context/GlobalContext';
import Footer from '../../../components/Footer/Footer';
import Header from '../../../components/Header';
import HeroSection from '../../../components/HeroSection';
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";
import { Col } from "react-bootstrap"
import Seo from "../../../components/Seo";
import Section from '../../../components/Section';
import SectionTitle from '../../../components/Section/SectionTitle';
import SectionStepByStep from '../../../components/SectionStepByStep';
import SectionScheduleAssessment from '../../../components/SectionScheduleAssessment/SectionScheduleAssessment';
import SectionJoinCommunity from '../../../components/SectionJoinCommunity';
import Inbetweener from '../../../components/Inbetweener';
import ListCheck from '../../../components/ListCheck/ListCheck';
import ListCheckItem from '../../../components/ListCheckItem';

const Home = ({pageContext, data, location}) => {

  // init page metadata
  const { pages } = useGlobalContext();
  const thisPage = pages.getByPath(location.pathname);

  // retrieve bg image
  const {
    imageCost,
    imageAdapt, 
    imageHero,
    imageJenga,
    imageFlexible
  } = data

  const bgImage = getImage(imageHero);
  const imgAdapt = getImage(imageAdapt);
  const imgCost = getImage(imageCost);
  const imgJenga = getImage(imageJenga);
  const imgFlexible= getImage(imageFlexible);
  
  // get breadcrumbs
  const {breadcrumb: { crumbs }} = pageContext

  return (
    <>
      <Seo title={thisPage?.Title} description={thisPage?.Description}/>

      <Header />

      {/* 
      HERO SECTION
      */}
      <HeroSection 
          title={thisPage?.Pagetitle}
          titleFullWidth={true}
          bgImage={bgImage}
          bgImagePosition="bottom"
          breadcrumbs={crumbs}
      />
      
      <Section id="what-is-btp" bgColor='light'>
        <Col className="col-lg-6 col-12">
          <div className="content-above mb-7">
            <SectionTitle >
            Is your current SAP landscape fragmented?
            </SectionTitle>
            <div className="features__item-2">
              <p>Many SAP customers are completely blocked due to their highly customized landscapes. The time required to keep the applications and systems up and running reduces the available time to focus on value-added tasks even more. Due to the non-scalable application development, the dependency on individual developers is very high. The longer SAP customers keep developing with ABAP within the core systems, the more it will cost in the end.</p>
            </div>
          </div>
        </Col>
        <Col className="col-lg-6 col-12 justify-content-center d-flex">
          <GatsbyImage className="rounded-4" image={imgAdapt} alt="man – thinking – man on a pc"/>
        </Col>
      </Section>

      <Section id="what-is-btp" bgColor='dark' verticalAlign='top'>
        <Col className="col-lg-6 col-12">
        <GatsbyImage className="rounded-4" image={imgCost} alt="SAP – BTP – migrate to cloud solutions"/>
        </Col>
        <Col className="col-lg-6 col-12 justify-content-center d-flex">
        <div className="content-above mb-7">
            <SectionTitle>
            Why migrate to cloud solutions and SAP BTP?
            </SectionTitle>
              <p>SAP Business Technology Platform (SAP BTP) enables businesses to achieve greater flexibility and independence in the cloud. With the technology offered by SAP BTP, businesses can move their assets to the cloud, integrate their IT landscape and configure and extend SAP and third-party applications. This provides users with the ability to unlock the full potential of their data and applications, allowing them to turn data into business value and create innovative new solutions.</p>
            <ListCheck>
              <ListCheckItem>Connected business processes<br />Optimize processes that cross lines of business and achieve competitive differentiation with SAP BTP embedded in SAP applications.</ListCheckItem>
              <ListCheckItem>Multi-cloud openness<br />Run SAP solutions in any hyper scaler cloud environment for flexibility and consumer choice.</ListCheckItem>
              <ListCheckItem>Fast time to market<br />Go live quickly, reduce the payback period and monetize investments.</ListCheckItem>
              <ListCheckItem>Future-proofed business<br />Innovate in new ways by adopting a cloud operations-based model on infrastructure-as-a-service (IaaS) technologies.</ListCheckItem>
              <ListCheckItem>Tailored transformation<br />Take advantage of opportunities with fully managed platform-as-a-service (PaaS) offerings to meet unique business needs and requirements, no matter if it's in the cloud or a hybrid scenario.</ListCheckItem>
              </ListCheck>
            </div>
          
        </Col>
      </Section>

      {/* 
      Inbetweener 
      */}
      <Inbetweener bgColor='light' textColor='dark'>
        Here's what will happen if you don't change...
      </Inbetweener>
      
      <Section id="fragmented-landscape" bgColor='dark'>
        <Col className="col-lg-6 col-12">
          <GatsbyImage className="rounded-4" image={imgJenga} alt="Jenga– playing – assembling jenga"/>
        </Col>
        <Col className="col-lg-6 col-12 justify-content-center d-flex">
        <div className="content-above mb-7">
            <SectionTitle>
            Your application landscape will become fully fragmented
            </SectionTitle>
            <div className="features__item-2">
              <p>With high dependencies between applications, as well as on the application developers itself. This will drastically reduce your ability to innovate and adapt quickly to fast-changing market conditions. It will be very difficult to add AI and Machine Learning to your existing processes. Every day the customer continues with custom coding in their core systems, the burden of managing the total application landscape becomes greater.</p>
            </div>
          </div>
        </Col>
      </Section>


      {/* 
      Inbetweener
        */}
      <Inbetweener bgColor='light' textColor='dark'>
        Keep the core clean
      </Inbetweener>

      {/* 
      SectionStepByStep
        */}
      <SectionStepByStep 
        bgColor='dark'
        imagePosition='right'
        title="So where do you start?"
        checkItems={[
          "Reduce complexity by building applications outside the core system.",
          "Even if you are running on SAP ECC, start moving your custom code to SAP BTP to make the transition to S/4 HANA faster and easier.",
          "Stop ABAP coding within the core and go to widespread programming languages with SAP BTP.",
          "Create modular and interchangeable building blocks to increase flexibility",
          "Start thinking big. Start incorporating intelligent technologies in your core SAP processes",
        ]}
        showButton={false}
      />
        
      {/*
      SCHEDULE ASSESSMENT
      */}
      <SectionScheduleAssessment bgColor='light' topics='services'
        title="Start with an Application Development Exploration and Advisory Session."
        subtitle="This is what we will cover during our first (online) meeting."
        items={[]}
        buttonText='Plan an exploration session'
      />

      {/*
        Inbetweener 
      */}
      <Inbetweener bgColor='dark' textColor='dark' smallText>
          Reduce complexity by building applications outside the core system
      </Inbetweener>
      

      <Section id="increase-your-flexibility" bgColor='light'>
        <Col className="col-lg-6 col-12">
          <div className="content-above mb-7">
            <SectionTitle >
            Increase your flexibility
            </SectionTitle>
            <ListCheck>
              <ListCheckItem>SAP Customers will increase the flexibility of their application landscape by implementing a composable business. This composable business is a modular set-up with interchangeable building blocks (apps), which ensures the business will be able to rearrange and reorient as needed depending on external (or internal) factors, such as a shift in customer values or sudden change in the supply chain or materials.</ListCheckItem>
              <ListCheckItem>The key element in this, is the ability to loosely coupled applications. This means that components have little or no knowledge of each other and act independently.</ListCheckItem>
              <ListCheckItem>In the end, you will improve the flexibility and scalability of your application development. You will increase innovation power, reduce costs and become less dependent on individual developers.</ListCheckItem>
            </ListCheck>
          </div>
        </Col>
        <Col className="col-lg-6 col-12 justify-content-center d-flex">
          <GatsbyImage className="rounded-4" image={imgFlexible} alt="man – thinking – man on a pc"/>
        </Col>
      </Section>

      {/*
      * SectionJoinCommunity
      */}
      <SectionJoinCommunity
        bgColor='dark'
        title="Subscribe and join 450 like-minded professionals in the “SAP BTP Community”"
        checkItems={[
          "How can I improve my innovation power",
          "I want to be able to react fast on changing business demands",
          "How to get rid of old ABAP customizations",        
        ]}
      />
      <Footer />
    </>
  );
};

export default Home;

export const query = graphql`
query Home {
  imageHero: file(relativePath: { eq: "bg_businesswoman-sitting-office-3d_1920x1280.jpg" }) {
    childImageSharp {
      gatsbyImageData(
        width: 2000
        height: 720
        quality: 50
        placeholder: BLURRED
        webpOptions: { quality: 80 }
      )
    }
  }
  imageAdapt: file(relativePath: { eq: "young-serious-handsome-man-entrepreneur-focused-into-portable-laptop-computer-prepares-business-project-meeting_1200x800.jpg" }) {
    childImageSharp {
      gatsbyImageData(
        width: 600
        quality: 50
        placeholder: BLURRED
        webpOptions: { quality: 70 }
      )
    }
  }
  imageJenga: file(relativePath: { eq: "planning-risk-strategy-business-businessman-gambling-placing-wooden-block-tower_1200x800.jpg" }) {
    childImageSharp {
     gatsbyImageData(
       width: 400
       height: 600
       quality: 50
       placeholder: BLURRED
       webpOptions: { quality: 70 }
      )
    }
  }
  imageCost: file(relativePath: { eq: "sap_diagram_btp.png" }) {
    childImageSharp {
      gatsbyImageData(
        width: 500
        quality: 50
        placeholder: BLURRED
        webpOptions: { quality: 70 }
      )
    }
  }
  imageFlexible: file(relativePath: { eq: "concentrated-man-freelancer-work-distantly-laptop-computer-has-stubble-wears-spectacles_1200x800.jpg" }) {
    childImageSharp {
    gatsbyImageData(
      width: 600
      quality: 50
      placeholder: BLURRED
      webpOptions: { quality: 70 }
    )
  }
}
}
`