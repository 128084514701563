import React from "react"

// all styles here
import 'react-modal-video/scss/modal-video.scss';
import 'react-image-lightbox/style.css';
import './src/scss/index.scss';
//import 'bootstrap/dist/js/bootstrap.min.js';

import { GlobalContextProvider } from './src/context/GlobalContext';
import CookieConsentDialog from "./src/components/CookieConsentDialog/CookieConsentDialog";

export const wrapRootElement = ({ element }) => (
    <GlobalContextProvider>
        {element}
        <CookieConsentDialog />
    </GlobalContextProvider>
)