import React from "react";
import { Accordion as ReactAccordion } from 'react-bootstrap';

const Accordion = ({ classes='', items=[] }) => {
    return (
        <>
            <ReactAccordion className={`${classes}`} defaultActiveKey="0">
                {items.map((item,index) => 
                    <ReactAccordion.Item key={`item-${index}`} eventKey={index} className="card">    
                        <ReactAccordion.Button bsPrefix="btn btn-link">{item.header}</ReactAccordion.Button>
                        <ReactAccordion.Body>
                            {item.body}
                        </ReactAccordion.Body>
                    </ReactAccordion.Item>
                )} 
            </ReactAccordion>
        </>
    )
}

export default Accordion;