import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import Button from "../Button"
import { useGlobalContext } from '../../context/GlobalContext';
import { useCookies } from 'react-cookie';
import Modal from 'react-bootstrap/Modal';

const CookieConsentDialog = () => {
    
    const consentCookie = 'AariniCookiesConsent'
    const {cookieConsentBarOpen, setCookieConsentBarOpen, pages} = useGlobalContext()
    
    const cookieTagManager = 'gatsby-gdpr-google-tagmanager'
    const [cookies, setCookie] = useCookies([consentCookie])

    // Extra options
    const expiry = 30 // days
    let expDate = new Date()
    expDate.setDate(expDate.getDate() + expiry);
    const options = {
        expires: expDate,
        path: '/'
    }
    
    const onAccept = () => {
        setCookieConsentBarOpen(false)
        setCookie(consentCookie, true, options)
        setCookie(cookieTagManager, true, options)
    }

    const onDecline = () => {
        setCookieConsentBarOpen(false)
        setCookie(consentCookie, false, options)
        setCookie(cookieTagManager, false, options)
    }
    const handleClose = () => setCookieConsentBarOpen(false);
    //const handleShow = () => setCookieConsentBarOpen(true);

    // Handle SSR
    const [mounted, setMounted] = useState(false);
    useEffect(()=> {
        setMounted(true)
    }, [mounted])

    return (
        <>
            {mounted && <Modal
                show={!(consentCookie in cookies) && cookieConsentBarOpen}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                centered
                size="sm"
            >
                <Modal.Header>
                    <Modal.Title>This website uses cookies</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    This helps us to keep the website functional, and get statistical insights. See our <Link to={pages.getByTitle('Cookies and privacy')?.Path}>cookies and privacy statement</Link>.
                </Modal.Body>
                <Modal.Footer>
                    <Button size="sm" OnClick={onAccept}>Accept</Button>
                    <Button size="sm" OnClick={onDecline} variant="transparent">Reject</Button>
                </Modal.Footer>
            </Modal>}
        </>
    )
  }

export default CookieConsentDialog