import * as React from 'react';

import { useGlobalContext } from '../../context/GlobalContext';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header';
import HeroSection from '../../components/HeroSection';
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";
import { Col, Row } from "react-bootstrap"
import Seo from "../../components/Seo";
import Section from '../../components/Section';
import SectionTitle from '../../components/Section/SectionTitle';
import ListCheck from '../../components/ListCheck/ListCheck';
import ListCheckItem from '../../components/ListCheckItem';
import Inbetweener from '../../components/Inbetweener';
import SectionScheduleAssessment from '../../components/SectionScheduleAssessment';
import SectionStepByStep from '../../components/SectionStepByStep';

const Home = ({pageContext, data, location}) => {

  // init page metadata
  const { pages } = useGlobalContext();
  const thisPage = pages.getByPath(location.pathname);

  // retrieve bg image
  const { 
    imageHero,
    imageSapOverview,
    imageSapChannels,
    imageSapIntegration,
    imageSapEvent,
    imageSapIntelligence,
    imageSapExtensions
   } = data

  const bgImage = getImage(imageHero);
  const imgSapChannels = getImage(imageSapChannels);
  const imgSapOverview = getImage(imageSapOverview);
  const imgSapIntegration = getImage(imageSapIntegration);
  const imgSapEvent = getImage(imageSapEvent);
  const imgSapIntelligence = getImage(imageSapIntelligence);
  const imgSapExtensions = getImage(imageSapExtensions);
  
  // get breadcrumbs
  const {breadcrumb: { crumbs }} = pageContext

  return (
      <>
        <Seo title={thisPage?.Title} description={thisPage?.Description}/>

        <Header />

        {/* 
        HERO SECTION
        */}
        <HeroSection 
            title={thisPage?.Pagetitle}
            titleFullWidth={true}
            bgImage={bgImage}
            button={true}
            buttonScrollElement="what-is-btp"
            breadcrumbs={crumbs}
        />
        
        <Section id="what-is-btp" bgColor='light' verticalAlign='start'>
          <Col className="col-lg-6 col-12">
            <div className="content-above mb-7">
              <SectionTitle>
                What is SAP BTP?
              </SectionTitle>
              <p className="col-12 col-lg-10">Today, organizations are accumulating exponentially larger volumes of customer data, and it often becomes fragmented, duplicated, inconsistent, incomplete, ungoverned and out-of-date as it travels throughout the organization. We solve challenges such as: Structuring data silos, governance processes, Setup Real-time data and the integration of data.</p>
            </div>
          </Col>
          <Col className="col-lg-6 col-12">
            <div className="features__item-2">
              <GatsbyImage image={imgSapOverview} alt="A diagram with icons representing core SAP features"/>
            </div>
          </Col>
        </Section>

        {/*
        INBETWEENER
        */}
        <Inbetweener>
          Let’s find out what SAP Business Technology Platform is all about
        </Inbetweener>
        
        <Section id="why-use-btp" bgColor='light' verticalAlign='start'>
          <Col className="col-lg-6 col-12">
            <div className="content-above mb-7">
              <SectionTitle>
                Why start using SAP BTP?
              </SectionTitle>
              <ListCheck classes="col-lg-10">
                <ListCheckItem>Lower your TCO</ListCheckItem>
                <ListCheckItem>Innovation of core (SAP) processes</ListCheckItem>
                <ListCheckItem>High scalability</ListCheckItem>
                <ListCheckItem>Quick adaption to fast-changing market conditions</ListCheckItem>
                <ListCheckItem>It includes intelligent enterprise applications with database and data management, analytics, integration and extension capabilities in a single platform for both cloud and hybrid environments</ListCheckItem>
              </ListCheck>
            </div>
          </Col>
          <Col className="col-lg-5 offset-lg-1 col-12">
            <div className="features__item-2">
              <GatsbyImage image={imgSapChannels} alt="A diagram with diagrams showing SAP channels like app desktop shopping-cart intelligence" />
            </div>
          </Col>
        </Section>

        {/*
        INBETWEENER
        */}
        <Inbetweener smallText>
          Enterprises today need to be ready to adapt to fast changing market conditions and need to be supported by real-time data
        </Inbetweener>

        <Section id="sap-most-used-services" bgColor='light' verticalAlign='start'>
          <Col className="col-lg-5 col-12">
            <div className="content-above mb-7">
              <SectionTitle small>
                What kind of services are used most within the SAP Business Technology Platform?
              </SectionTitle>
            </div>
          </Col>
          <Col className="col-lg-7 col-12">
              <Row className="align-items-stretch">
                <Col className="col-xl-6 col-12 mb-3">
                  <div className="features__item-2 h-100">
                    <GatsbyImage className="mb-4" image={imgSapIntegration} alt="Icon representing SAP integrations" />
                    <h3>SAP Integration Suite</h3>
                  </div>
                </Col>
                <Col className="col-xl-6 col-12 mb-3">
                  <div className="features__item-2 h-100">
                    <GatsbyImage className="mb-4" image={imgSapEvent} alt="Icon representing SAP integrations" />
                    <h3>SAP event mesh</h3>
                  </div>
                </Col>
                <div className="w-100"></div>
                <Col className="col-xl-6 col-12 mb-3">
                  <div className="features__item-2 h-100">
                    <GatsbyImage className="mb-4" image={imgSapIntelligence} alt="Icon representing SAP integrations" />
                    <h3>SAP Data Intelligence</h3>
                  </div>
                </Col>
                <Col className="col-xl-6 col-12 mb-3">
                  <div className="features__item-2 h-100">
                    <GatsbyImage className="mb-4" image={imgSapExtensions} alt="Icon representing SAP integrations" />
                    <h3>SAP Extension Suite</h3>
                  </div>
                </Col>
              </Row>
          </Col>
        </Section>

        {/*
        INBETWEENER
        */}
        <Inbetweener smallText>
          83% of global IT professionals survey participants say improving enterprise integration is a top priority for the next 1 to 2 years
        </Inbetweener>

        <SectionStepByStep 
          title="Why migrate to SAP BTP?"
          checkItems={[
            "Connected business processes",
            "Multi-cloud openness",
            "Fast time to value",
            "Extensive partner ecosystem"
          ]}
          showButton={false}
        />

        <SectionScheduleAssessment 
          title="Start with an exploration and advisory session based on your current architecture"
          buttonText='Plan a session'
          topics="services"
        />

        <Footer/>
      </>
  );
};

export default Home;

export const query = graphql`
query Home {
  imageHero: file(relativePath: { eq: "bg_cyber-network-2560x1440-internet-6k-18684.jpg" }) {
    childImageSharp {
      gatsbyImageData(
        width: 2000
        quality: 50
        placeholder: BLURRED
        webpOptions: { quality: 70 }
      )
    }
  }
  imageSapOverview: file(relativePath: { eq: "sap_diagram_btp_overview_2_963x429.png" }) {
    childImageSharp {
      gatsbyImageData(
        width: 600
        quality: 50
        placeholder: NONE
        webpOptions: { quality: 70 }
      )
    }
  }
  imageSapChannels: file(relativePath: { eq: "sap_diagram_btp.png" }) {
    childImageSharp {
      gatsbyImageData(
        width: 600
        quality: 50
        placeholder: NONE
        webpOptions: { quality: 70 }
      )
    }
  }
  imageSapIntegration: file(relativePath: { eq: "sap_icon_integration_suite.png" }) {
    childImageSharp {
      gatsbyImageData(
        width: 120
        quality: 50
        placeholder: NONE
        webpOptions: { quality: 70 }
      )
    }
  }
  imageSapEvent: file(relativePath: { eq: "sap_icon_event mesh.png" }) {
    childImageSharp {
      gatsbyImageData(
        height: 120
        quality: 50
        placeholder: NONE
        webpOptions: { quality: 70 }
      )
    }
  }
  imageSapIntelligence: file(relativePath: { eq: "sap_icon_intelligent suite.png" }) {
    childImageSharp {
      gatsbyImageData(
        height: 120
        quality: 50
        placeholder: NONE
        webpOptions: { quality: 70 }
      )
    }
  }
  imageSapExtensions: file(relativePath: { eq: "sap_icon_extension suite.png" }) {
    childImageSharp {
      gatsbyImageData(
        height: 120
        quality: 50
        placeholder: NONE
        webpOptions: { quality: 70 }
      )
    }
  }
}
`