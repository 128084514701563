import * as React from 'react';
import { Link } from 'gatsby';
import { FaPlus } from 'react-icons/fa';
import { useGlobalContext } from '../context/GlobalContext';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header';
import HeroSection from '../components/HeroSection';
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";
import { Row, Col } from "react-bootstrap"
import Seo from "../components/Seo";
import Section from '../components/Section';
import SectionTitle from '../components/Section/SectionTitle';
import SingleTeam from '../components/SingleTeam';
import { useTeam } from '../hooks/useTeam';
import ContactForm from '../components/ContactForm/ContactForm';

const Home = ({pageContext, data, location}) => {

  // init page metadata
  const { pages } = useGlobalContext();
  const thisPage = pages.getByPath(location.pathname);

  // retrieve bg image
  const { 
    imageHero,
    imageCTA
   } = data

  const bgImage = getImage(imageHero);
  const bgCTA = getImage(imageCTA);
  
  // get breadcrumbs
  const {breadcrumb: { crumbs }} = pageContext
  
  const team = useTeam()

  return (
      <>
        <Seo title={thisPage?.Title} description={thisPage?.Description}/>

        <Header />

        {/* 
        HERO SECTION
        */}
        <HeroSection 
            title={thisPage?.Pagetitle}
            titleFullWidth={true}
            bgImage={bgImage}
            breadcrumbs={crumbs}
        />

        <Section 
          id="management"
          horizontalAlign='center'
        >
          {["Management", "Experts"].map((cat,ci) =>
            <div key={`${cat}-${ci}`}>
              <SectionTitle align='center'>
                {cat}
              </SectionTitle>
              <Row className="mb-9">
                {team.getByCategory(cat).map((t,ti) => 
                  <Col key={`worker-${ti}`} className="col-lg-3 col-12 mb-4">
                    <SingleTeam
                      key={`${cat}-worker-${ti}`}
                      jobTitle={t.JobTitle}
                      about={t.About}
                      name={t.Title}
                      image={<GatsbyImage image={getImage(t.AvatarImage)} alt={`Avatar photo of ${t.Title}`} />}
                      linkedIn={t.LinkedIn}
                    />
                  </Col>
                )}
                 <div className="col-lg-3 col-12 mb-4 col">
                     <div style={{backgroundColor:'#f9f9f9'}} className="team__item team__item-add p-relative text-center fix mb-30" >
                        <div className="team__add">
                           <Link to="/careers"><i > <FaPlus/> </i></Link>
                        </div>
                     </div>
                  </div>
              </Row>
            </div>
          )}
        </Section>

        <Section 
          id="section-cta"
          thumb={bgCTA}
          thumbPosition='full'
          horizontalAlign='center'
        >
          <ContactForm 
            title={<>Questions? <br />Get in touch with us</>} 
            text="Please let us know what your questions or challenges are. We will get back to you as soon as possible."
            buttonText="Send"
          />
        </Section>

        <Footer/>
      </>
  );
};

export default Home;

export const query = graphql`
query Home {
  imageHero: file(relativePath: { eq: "bg_aarini_office_workers_talk_1920x1280.jpg" }) {
    childImageSharp {
      gatsbyImageData(
        width: 2000
        quality: 50
        placeholder: BLURRED
        webpOptions: { quality: 70 }
      )
    }
  }
  imageCTA: file(relativePath: { eq: "bg_digital-cyberspace-data-network-connections_1920x1080.jpg" }) {
    childImageSharp {
      gatsbyImageData(
        width: 2000
        quality: 50
        placeholder: BLURRED
        webpOptions: { quality: 70 }
      )
    }
  }
}`