import React from 'react';
import { Col } from "react-bootstrap";
import Section from '../Section/Section';
import SectionTitle from '../Section/SectionTitle';

const SectionFeature = ({
   title='',
   image='',
   text='',
   bgColor='light',
   id='section-feature',
   classes='',
   verticalAlign='center',
   children
}) => {

   return (
      <Section id={id} bgColor={bgColor} classes={classes} verticalAlign={verticalAlign}>
         <Col className="col-lg-5 col-12">
            <div className="">
               <SectionTitle classes="">
                  {title}
               </SectionTitle>
            </div>
         </Col>
         <Col className="col-lg-6 offset-lg-1 col-12">
            <div className="features__item-2">
               {image}
               <div>{text}</div>
            </div>
         </Col>
         {children}
      </Section>
   )
}

export default SectionFeature;