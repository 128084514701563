import React from 'react';
import { Link } from 'gatsby';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { useGlobalContext } from '../../context/GlobalContext';

const Breadcrumbs = ({parts=[]}) => {
   
   const {pages} = useGlobalContext()
   return (
      <Breadcrumb>
         {parts.map((b,i) => 
            <Breadcrumb.Item key={`breadcrumb-${i}`} linkAs="span">
               <Link to={pages.getByPath(b.pathname)?.Placeholder ? '' : pages.getByPath(b.pathname)?.Path}>{pages.getByPath(b.pathname)?.Breadcrumb || b.crumbLabel}</Link>
            </Breadcrumb.Item>
         )}
    </Breadcrumb>
   );
};

export default Breadcrumbs;