import * as React from 'react';

import { useGlobalContext } from '../../context/GlobalContext';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header';
import HeroSection from '../../components/HeroSection';
import { getImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";
import Seo from "../../components/Seo";
import SectionStepByStep from '../../components/SectionStepByStep';
import SectionScheduleAssessment from '../../components/SectionScheduleAssessment/SectionScheduleAssessment';
import SectionFeature from '../../components/SectionFeature/SectionFeature';

// import icons
import moneyIcon from "../../assets/icons/solid/025-money.svg";
import blockchainIcon from "../../assets/icons/solid/026-blockchain.svg";
import touchIcon from "../../assets/icons/solid/043-touch.svg";

const Approach = ({pageContext, data, location}) => {

  // init page metadata
  const { pages } = useGlobalContext();
  const thisPage = pages.getByPath(location.pathname);
  
  // get breadcrumbs
  const {breadcrumb: { crumbs }} = pageContext

  // retrieve bg image
  const { 
    imageCyberSpace
   } = data

  const bgImage = getImage(imageCyberSpace);
  
  return (
      <>
        <Seo title={thisPage?.Title} description={thisPage?.Description}/>

        <Header />

        {/* 
        HERO SECTION
        */}
        <HeroSection 
            title={thisPage?.Pagetitle}
            bgImage={bgImage}
            buttonScrollElement="approach"
            breadcrumbs={crumbs}
        />

        <SectionFeature 
          title="Scalability towards the future"
          image={<img className="mb-4" src={moneyIcon} alt="Icon chart with a dollar sign above representing money increase" />}
          text="One of the most important challenges for enterprises is scalability. To reach this goal, enterprise organizations need to ‘Keep the core clean’: Reduce complexity, move applications to the cloud and create modular and interchangeable building blocks to increase flexibility."
        />
        
        <SectionFeature 
          title="Build customizations outside the core systems"
          image={<img className="mb-4" src={blockchainIcon} alt="Icon representing packets connected together" />}
          text="The message to SAP users is clear. To remain agile and capable of innovation, we leave the core of your ERP system as it is. If you build customizations, do so outside of your core system, in other words: 'Keep the core clean'. This simplifies maintenance based on S/4HANA updates. To increase standardization even further, S/4HANA cloud is the preferred option, as SAP automatically performs the updates every quarter. <br />
          You can build customizations within the core systems, should you achieve higher cost efficiency. This cannot be achieved within the standard software and you should only choose this when it creates additional value compared to your competitors. But think about this carefully, because what seems like a great advantage today may increase complexity tomorrow."
        />

        <SectionFeature 
          title="But how do you handle customization?"
          image={<img className="mb-4" src={touchIcon} alt="Icon representing a finger touching a data connection" />}
          text={<>If you do implement customizations, these should be developed and deployed alongside the core, on a modern Cloud Platform, viewing all customizations as separate components, loosely coupled to the core, preferably with a stable and standard API. SAP Cloud Platform offers an excellent opportunity to do so. <br /><br />Get in touch if you want to transfer customizations to the cloud.</>}
        />

        {/*
        STEP BY STEP APPROACH
        */}
        <SectionStepByStep 
          showButton={false}
          title="Step by step approach to keep the core clean"
          checkItems={[
            "Reduce complexity by building applications outside the core system.",
            "Even if you are running on SAP ECC, start moving your custom code to SAP BTP to make the transition to S/4 HANA faster and easier.",
            "Stop coding in ABAP, and go to widespread programming languages with SAP BTP.",
            "Create modular and interchangeable building blocks to increase flexibility.",
            "Start thinking big. Start incorporating intelligent technologies in your core SAP processes."
          ]}
        />

        {/*
        SCHEDULE ASSESSMENT
        */}
        <SectionScheduleAssessment 
          title="Schedule an Exploration and Advisory Session"
          subtitle="This is what we will cover during our first (online) meeting."
          items={[]}
        />

        <Footer/>
      </>
  );
};

export default Approach;

export const query = graphql`
query Home {
  imageCyberSpace: file(relativePath: { eq: "bg_digital-cyberspace-data-network-connections_1920x1080.jpg" }) {
    childImageSharp {
      gatsbyImageData(
        width: 2000
        quality: 50
        placeholder: BLURRED
        webpOptions: { quality: 70 }
      )
    }
  }
}
`