import * as React from 'react';

import { useGlobalContext } from '../context/GlobalContext';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header';
import HeroSection from '../components/HeroSection';
import { getImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";
import { Row, Col } from "react-bootstrap"
import Seo from "../components/Seo";
import Section from '../components/Section';
import ContactForm from '../components/ContactForm/ContactForm';
import SectionTitle from '../components/Section/SectionTitle';

import { FaPhone } from 'react-icons/fa';
import { FaEnvelope } from 'react-icons/fa';

const Home = ({pageContext, data, location}) => {

  // init page metadata
  const { pages, variables } = useGlobalContext();
  const thisPage = pages.getByPath(location.pathname);

  // retrieve bg image
  const { 
    imageHero,
    imageCTA
   } = data

  const bgImage = getImage(imageHero);
  const bgCTA = getImage(imageCTA);
  
  // get breadcrumbs
  const {breadcrumb: { crumbs }} = pageContext

  return (
      <>
        <Seo title={thisPage?.Title} description={thisPage?.Description}/>

        <Header />

        {/* 
        HERO SECTION
        */}
        <HeroSection 
            title={thisPage?.Pagetitle}
            titleFullWidth={true}
            bgImage={bgImage}
            breadcrumbs={crumbs}
        />
    
        <Section 
          id="section-cta"
          horizontalAlign='center'
          verticalAlign='start'
        >
          <SectionTitle align='center'>Let's get in touch</SectionTitle>
          <Row className="align-items-stretch">
            <Col className="col-12 col-lg-4">
              <div className="features__item-2 h-100">
                <h3>Netherlands</h3>
                <p>{variables.get('Address NL')}</p>
                <p>
                  <i className="me-3"><FaPhone /></i>
                  <a href={`call:${variables.get('Phone NL')}`}>{variables.get('Phone NL')}</a>
                </p>
                <p>
                  <i className="me-3"><FaEnvelope /></i>
                  <a target="_blank" rel="noreferrer" href={`mailto:${variables.get('Email sales')}`}>
                    {variables.get('Email sales')}
                  </a>
                </p>
              </div>
            </Col>
            <Col className="col-12 col-lg-4">
              <div className="features__item-2 h-100">
                <h3>India</h3>
                <p>{variables.get('Address IN')}</p>
                <p>
                  <i className="me-3"><FaPhone /></i>
                  <a href={`call:${variables.get('Phone IN')}`}>{variables.get('Phone IN')}</a>
                </p>
                <p>
                  <i className="me-3"><FaEnvelope /></i>
                  <a target="_blank" rel="noreferrer" href={`mailto:${variables.get('Email sales')}`}>
                    {variables.get('Email sales')}
                  </a>
                </p>
              </div>
            </Col>
            <Col className="col-12 col-lg-4">
              <div className="features__item-2 h-100">
                <h3>United Arab Emirates</h3>
                <p>{variables.get('Address UAE')}</p>
                <p>
                  <i className="me-3"><FaPhone /></i>
                  <a target="_blank" rel="noreferrer" href={`call:${variables.get('Phone UAE')}`}>
                    {variables.get('Phone UAE')}
                  </a>
                </p>
                <p>
                  <i className="me-3"><FaEnvelope /></i>
                  <a href={`mailto:${variables.get('Email sales')}`}>{variables.get('Email sales')}</a>
                </p>
              </div>
            </Col>
          </Row>
        </Section>

        <Section 
          id="section-contact-form"
          thumb={bgCTA}
          thumbPosition='full'
          horizontalAlign='center'
        >
          <ContactForm 
            title="Contact us"
            text="Please let us know what your questions or challenges are. We will get back to you as soon as possible."
            buttonText="Send"
          />
        </Section>

        <Footer/>
      </>
  );
};

export default Home;

export const query = graphql`
query Home {
  imageHero: file(relativePath: { eq: "bg_contact_1920x609.jpg" }) {
    childImageSharp {
      gatsbyImageData(
        width: 2000
        quality: 50
        placeholder: BLURRED
        webpOptions: { quality: 70 }
      )
    }
  }
  imageCTA: file(relativePath: { eq: "bg_digital-cyberspace-data-network-connections_1920x1080.jpg" }) {
    childImageSharp {
      gatsbyImageData(
        width: 2000
        quality: 50
        placeholder: BLURRED
        webpOptions: { quality: 70 }
      )
    }
  }
}`