import React from "react";
import Seo from "../components/Seo";
import Button from "../components/Button"

const PageNotFound = ({location}) => {
  return (
    <>
      <Seo title="Page not found" />
      <div className="w-100 vh-100 d-flex align-items-center 404-page">
        <div className="container">
          <div className="row justify-content-center">
            <h1 className="text-center mb-5">
              Sorry, <br/>we don't have this page here!
            </h1>
            <Button to="/" classes="w-auto">
              Go to homepage
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
export default PageNotFound;
