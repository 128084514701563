import * as React from 'react';

import { useGlobalContext } from '../../../context/GlobalContext';
import Footer from '../../../components/Footer';
import Header from '../../../components/Header';
import HeroSection from '../../../components/HeroSection';
import { getImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";
import Seo from "../../../components/Seo";
import Section from '../../../components/Section';
import SectionTitle from '../../../components/Section/SectionTitle';
import SectionSubtitle from '../../../components/Section/SectionSubtitle';
import SectionContentRight from '../../../components/Section/SectionContentRight';
import ListCheck from '../../../components/ListCheck';
import ListCheckItem from '../../../components/ListCheckItem';
import Button from '../../../components/Button';
import ServicesTilesThree from '../../../components/ServicesTilesThree';
import Inbetweener from '../../../components/Inbetweener';
import SectionCTA from '../../../components/SectionCTA';

//icons
import IconTCO from '../../../assets/icons/solid/040-hand.svg';
import IconApp from '../../../assets/icons/solid/031-route.svg';
import IconSap from '../../../assets/icons/solid/010-monitor.svg';

const Home = ({pageContext, data, location}) => {

  // init page metadata
  const { pages } = useGlobalContext();
  const thisPage = pages.getByPath(location.pathname);

  // retrieve bg image
  const { 
    imageHero,
    imageSolutions
   } = data

  const bgImage = getImage(imageHero);
  const thumbSolutions = getImage(imageSolutions);
  
  // get breadcrumbs
  const {breadcrumb: { crumbs }} = pageContext

  return (
      <>
        <Seo title={thisPage?.Title} description={thisPage?.Description}/>

        <Header />

        {/* 
        HERO SECTION
        */}
        <HeroSection 
            title={thisPage?.Pagetitle}
            titleFullWidth={true}
            bgImage={bgImage}
            button={true}
            buttonScrollElement="start-here"
            breadcrumbs={crumbs}
        >
          <div className="content-under pt-8" id="start-here">
            <ServicesTilesThree 
              data={[
                {
                  icon: IconTCO,
                  title: 'Learn how Cloud Platform Integration lowers your TCO',
                  to: pages.getByTitle('Cloud platform integration')?.Path
                },
                {
                  icon: IconApp,
                  title: 'Transform to a solid, future-proof Integration Foundation',
                  to: pages.getByTitle('Solid integration foundation')?.Path
                },
                {
                  icon: IconSap,
                  title: 'How to create a Future Enterprise Data Roadmap for SAP & Non-SAP',
                  to: pages.getByTitle('Future enterprise data roadmap')?.Path
                }
              ]}
            />
          </div>
        </HeroSection>
        
        {/*
        INBETWEENER
        */}
        <Inbetweener>
          Switch to a future-proof landscape with scalable integrations
        </Inbetweener>

        {/*
        INNOVATIVE SOLUTIONS
        */}
        <Section 
          id="most-frequent-challenges-when-working-sap"
          bgColor='dark'
          thumb={thumbSolutions}
        >
          <SectionContentRight>
            <SectionTitle small={true}>
              Simplify and scale your integrations
            </SectionTitle>
            <SectionSubtitle>
              Here are the latest questions from the community about simplifying and scaling your integrations to adapt to fast-changing market conditions:
            </SectionSubtitle>
            <ListCheck classes="mb-6">
              {[
                "Is SAP Business Technology Platform only suitable for S/4 HANA or is it also smart to start using SAP BTP while still on SAP ECC?",
                "How does SAP BTP reduce my TCO?",
                "How does SAP BTP help me to become future-proof?",
              ].map((item, index) => <ListCheckItem key={`item-${index}`}>{item}</ListCheckItem>)}
            </ListCheck>
            <Button to={pages.getByTitle('Community').Path}>Join our community</Button>
          </SectionContentRight>
        </Section>

        {/*
        SECTION CTA
        */}
        <SectionCTA 
          title="Want to know how we can help?" 
          buttons={[
            {to: 'Approach', text: 'See our approach'}
          ]} 
          id="section-see-approach"
        />

        <Footer/>
      </>
  );
};

export default Home;

export const query = graphql`
query Home {
  imageHero: file(relativePath: { eq: "bg_innovation-finance-concept_1920x1280.jpg" }) {
    childImageSharp {
      gatsbyImageData(
        width: 2000
        quality: 50
        placeholder: BLURRED
        webpOptions: { quality: 70 }
      )
    }
  }
  imageSolutions: file(relativePath: { eq: "businessmen-holding-dices-with-company-ideas-front-view_1200x860.jpg" }) {
    childImageSharp {
      gatsbyImageData(
        width: 600
        quality: 50
        placeholder: BLURRED
        webpOptions: { quality: 70 }
      )
    }
  }
}
`